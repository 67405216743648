import { ArrowRightOutlined } from "@mui/icons-material";
import {
  Avatar,
  Card,
  CardContent,
  Container,
  Divider,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import { addMinutes } from "date-fns";
import { addHours } from "date-fns/esm";
import { useEffect, useState } from "react";
import AppointmentsController from "../../api/appointments/appointments";
import { Appointment } from "../../api/appointments/appointmentTypes";
import { UserRole } from "../../api/users/usersTypes";
import ErrorDisplay from "../../components/errorDisplay";
import useAuth from "../../hooks/auth";
import AppointmentCard from "./appointmentCard";

const Dashboard = () => {
  const auth = useAuth();
  const appointmentsController = new AppointmentsController(auth.getToken());

  const [appointments, setAppointments] = useState<Appointment[]>([]);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    if (!auth.loading) {
      getFeed();
    }
  }, [auth.loading]);

  const getFeed = async () => {
    const dto = await appointmentsController.getFeed();

    if (dto.isError) {
      setError(dto.statusText);
      return;
    }

    setAppointments(dto.data);
    console.log(dto.data);
  };

  return (
    <Container
      maxWidth={false}
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: {
          xs: "column-reverse",
          md: "row",
        },
      }}
    >
      <ErrorDisplay error={error} setError={setError}></ErrorDisplay>
      <Box
        sx={{
          width: "50%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          height: "auto",
          mt: {
            xs: 5,
            md: 0,
          },
        }}
      >
        Beräkningar
      </Box>
      <Box
        sx={{
          width: "50%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          height: "auto",
          pt: 5,
        }}
      >
        <Typography
          variant="h4"
          sx={{
            mb: 3,
          }}
        >
          Kommande bokningar
        </Typography>
        {appointments.map((appointment) => (
          <AppointmentCard
            title={appointment.title}
            start={appointment.start}
            end={appointment.end}
            tag={appointment.tag}
          ></AppointmentCard>
        ))}

        {appointments.length === 0 && <Typography>Här var det tomt</Typography>}
      </Box>
    </Container>
  );
};

export default Dashboard;
