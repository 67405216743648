import { Dimensions, TemperatureDifference } from "./sharedTypes";

export const getFlow = (
  effect: number,
  temperatureDifference: TemperatureDifference,
  isPerSecond: boolean
): number => {
  const multiplier = isPerSecond ? 1 : 3600;

  let result = (effect * multiplier) / (temperatureDifference * 4180);

  if (result !== Math.round(result)) {
    result = Number(result.toFixed(2));
  }

  return result;
};

export const getFlowDimensions = (
  flow: number,
  isPerSecond: boolean
): Dimensions => {
  let dimensions: Dimensions = {
    pex: "",
    copper: "",
  };

  if (!isPerSecond) {
    flow = flow / 3600;
  }

  if (flow <= 0.008) {
    dimensions.pex = "12x2,0";
  } else if (flow <= 0.02 && flow > 0.008) {
    dimensions.pex = "16x2,0";
  } else if (flow <= 0.06 && flow > 0.02) {
    dimensions.pex = "20x2,0";
  } else if (flow <= 0.12 && flow > 0.06) {
    dimensions.pex = "25x2,3";
  } else if (flow <= 0.25 && flow > 0.12) {
    dimensions.pex = "32x2,9";
  } else if (flow <= 0.47 && flow > 0.25) {
    dimensions.pex = "40x3,7";
  } else if (flow <= 0.75 && flow > 0.47) {
    dimensions.pex = "50x4,6";
  } else if (flow <= 1.5 && flow > 0.75) {
    dimensions.pex = "63x5,7";
  } else if (flow <= 2.5 && flow > 1.5) {
    dimensions.pex = "75x6,8";
  } else if (flow <= 4 && flow > 2.5) {
    dimensions.pex = "90x8,2";
  } else if (flow <= 7 && flow > 4) {
    dimensions.pex = "110x10,0";
  }

  // ----------------------------------------------------------------------------------------------------------

  // --------------------------      KOPPAR RÖR dimensioner för l/s        --------------------------------------------

  if (flow <= 0.017) {
    dimensions.copper = "12x1,0";
  } else if (flow <= 0.038 && flow > 0.017) {
    dimensions.copper = "15x1,0";
  } else if (flow <= 0.065 && flow > 0.038) {
    dimensions.copper = "18x1,0";
  } else if (flow <= 0.12 && flow > 0.065) {
    dimensions.copper = "22x1,0";
  } else if (flow <= 0.22 && flow > 0.12) {
    dimensions.copper = "28x1,2";
  } else if (flow <= 0.4 && flow > 0.22) {
    dimensions.copper = "35x1,5";
  } else if (flow <= 0.7 && flow > 0.4) {
    dimensions.copper = "42x1,5";
  } else if (flow <= 1.4 && flow > 0.7) {
    dimensions.copper = "54x1,5";
  } else if (flow <= 2.7 && flow > 1.4) {
    dimensions.copper = "70x2,0";
  } else if (flow <= 5.1 && flow > 2.7) {
    dimensions.copper = "76,1x2,0";
  }

  return dimensions;
};
