import {
  Avatar,
  Box,
  Card,
  CardContent,
  createStyles,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
} from "@mui/material";
import { formatRelative } from "date-fns";
import { Company } from "../../../../api/companies/companiesTypes";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import { sv } from "date-fns/locale";

const flashCardsStyle = createStyles({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flexDirection: "column",
  width: "200px",
  p: 5,
  bgcolor: "#AE3C5F",
  color: "primary.light",
  transition: "all 0.1s ease-in-out",

  "&:hover": {
    transform: "scale(1.05)",
  },
});

export interface InformationTabProps {
  company: Company;
}

const InformationTab: React.FC<InformationTabProps> = ({ company }) => {
  return (
    <>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "space-around",
          alignItems: "center",
          flexDirection: {
            xs: "column",
            sm: "column",
            md: "row",
          },
        }}
      >
        <Card>
          <CardContent sx={flashCardsStyle}>
            <Typography
              sx={{
                fontSize: "1.4rem",
                mb: 1,
              }}
            >
              Prenumeration
            </Typography>
            <Typography
              sx={{
                fontWeight: "bold",
                mb: 1,
              }}
            >
              Premium
            </Typography>
          </CardContent>
        </Card>
        <Card>
          <CardContent sx={flashCardsStyle}>
            <Typography
              sx={{
                fontSize: "1.4rem",
                mb: 1,
              }}
            >
              Inkomst
            </Typography>
            <Typography
              sx={{
                fontWeight: "bold",
                mb: 1,
              }}
            >
              2000 kr
            </Typography>
          </CardContent>
        </Card>

        <Card>
          <CardContent sx={flashCardsStyle}>
            <Typography
              sx={{
                fontSize: "1.4rem",
                mb: 1,
              }}
            >
              Kund sedan
            </Typography>
            <Typography
              sx={{
                fontWeight: "bold",
                mb: 1,
              }}
            >
              {formatRelative(new Date(company.createdAt), new Date(), {
                locale: sv,
              })}
            </Typography>
          </CardContent>
        </Card>
      </Box>

      <Card
        sx={{
          mt: 5,
        }}
      >
        <CardContent>
          <Typography
            variant="h5"
            sx={{
              m: 3,
            }}
          >
            Betalningar
          </Typography>

          <List
            sx={{ width: "100%", maxWidth: 360, bgcolor: "background.paper" }}
          >
            <ListItem>
              <ListItemAvatar>
                <Avatar
                  sx={{
                    bgcolor: "green",
                  }}
                >
                  <AttachMoneyIcon color="secondary" />
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary="Budget" secondary="Jan 9, 2014" />
            </ListItem>
            <ListItem>
              <ListItemAvatar>
                <Avatar
                  sx={{
                    bgcolor: "green",
                  }}
                >
                  <AttachMoneyIcon color="secondary" />
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary="Premium" secondary="Jan 7, 2014" />
            </ListItem>
            <ListItem>
              <ListItemAvatar>
                <Avatar
                  sx={{
                    bgcolor: "green",
                  }}
                >
                  <AttachMoneyIcon color="secondary" />
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary="Standard" secondary="July 20, 2014" />
            </ListItem>
          </List>
        </CardContent>
      </Card>
    </>
  );
};

export default InformationTab;
