import { Box, Button, ButtonGroup, Typography } from "@mui/material";
import { addDays, endOfWeek, format, startOfWeek } from "date-fns";
import { sv } from "date-fns/locale";
import React from "react";
import { View, Views } from "react-big-calendar";
import { useNavigate } from "react-router-dom";

export interface CalendarToolbarProps {
  setView: (view: View) => void;
  setDate: (date: Date) => void;
  date: Date;
  view: View;
}

const CalendarToolbar: React.FC<CalendarToolbarProps> = ({
  setView,
  setDate,
  date,
  view,
}) => {
  const navigate = useNavigate();

  const skipMultiplier = view === "day" ? 1 : 7;

  return (
    <Box
      sx={{
        width: "100%",
        mb: 3,

        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        flexDirection: {
          xs: "column-reverse",
          md: "row",
        },
      }}
    >
      <ButtonGroup>
        <Button onClick={() => setDate(addDays(date, -skipMultiplier))}>
          Föregående
        </Button>
        <Button onClick={() => setDate(new Date())}>Idag</Button>
        <Button onClick={() => setDate(addDays(date, skipMultiplier))}>
          Nästa
        </Button>
      </ButtonGroup>
      <Typography
        sx={{
          fontWeight: "bold",
          fontSize: "1.2rem",
          mb: {
            xs: 3,
            md: 0,
          },
        }}
      >
        {view === "day"
          ? format(date, "d MMMM", { locale: sv })
          : format(startOfWeek(date, { weekStartsOn: 1 }), "d MMMM", {
              locale: sv,
            }) +
            " - " +
            format(endOfWeek(date, { weekStartsOn: 1 }), "d MMMM", {
              locale: sv,
            })}
      </Typography>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          flexDirection: {
            xs: "column-reverse",
            md: "row",
          },
          mb: {
            xs: 3,
            md: 0,
          },
        }}
      >
        <ButtonGroup>
          <Button onClick={() => setView("week")}>Veckovy</Button>
          <Button onClick={() => setView("day")}>Dagsvy</Button>
        </ButtonGroup>

        <Button
          sx={{
            ml: { xs: 0, md: 3 },
            mb: {
              xs: 3,
              md: 0,
            },
          }}
          onClick={() => navigate("/kalender/bokning")}
        >
          Skapa bokning
        </Button>
      </Box>
    </Box>
  );
};

export default CalendarToolbar;
