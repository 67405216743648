import { Alert, Snackbar } from "@mui/material";
import React from "react";

export interface ErrorDisplayProps {
  error: string | null;
  setError: (value: string | null) => void;
}

const ErrorDisplay: React.FC<ErrorDisplayProps> = ({ error, setError }) => {
  return (
    <Snackbar
      open={error !== null}
      autoHideDuration={6000}
      onClose={() => {
        setError(null);
      }}
    >
      <Alert severity="error" sx={{ width: "100%" }}>
        {error}
      </Alert>
    </Snackbar>
  );
};

export default ErrorDisplay;
