import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormHelperText,
  OutlinedInput,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Company } from "../../../../api/companies/companiesTypes";

export interface EditTabProps {
  company: Company;
  updateCompany: (company: Company) => void;
  deleteCompany: () => void;
}

const EditTab: React.FC<EditTabProps> = ({
  company,
  updateCompany,
  deleteCompany,
}) => {
  const [editCompany, setEditCompany] = useState<Company>(company);
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);

  useEffect(() => {
    setEditCompany(company);
  }, [company]);

  const handleChange = (key: string, value: any) => {
    setEditCompany({
      ...editCompany,
      [key]: value,
    });
  };

  const onSubmit = () => {
    updateCompany(editCompany);
  };

  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
      }}
    >
      <Dialog
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Radera företag</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Är du säker på att du vill radera {company.name}?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDialogOpen(false)}>Avbryt</Button>
          <Button onClick={() => deleteCompany()} autoFocus>
            Radera
          </Button>
        </DialogActions>
      </Dialog>

      <FormControl sx={{ m: 1, width: "50ch" }} variant="outlined">
        <FormHelperText
          id="name-text"
          sx={{
            fontSize: { xs: "0.8rem", sm: "1rem", md: "1.2rem" },
            color: "primary.main",
            mx: 1,
          }}
        >
          Företagsnamn
        </FormHelperText>
        <OutlinedInput
          id="name-input"
          value={editCompany.name}
          onChange={(event) => {
            handleChange("name", event.target.value);
          }}
          aria-describedby="name-text"
          inputProps={{
            "aria-label": "Företagsnamn",
          }}
        />
      </FormControl>

      <FormControl sx={{ m: 1, width: "50ch" }} variant="outlined">
        <FormHelperText
          id="phone-text"
          sx={{
            fontSize: { xs: "0.8rem", sm: "1rem", md: "1.2rem" },
            color: "primary.main",
            mx: 1,
          }}
        >
          Telefonnummer
        </FormHelperText>
        <OutlinedInput
          id="phone-input"
          value={editCompany.phone}
          onChange={(event) => {
            handleChange("phone", event.target.value);
          }}
          aria-describedby="phone-text"
          inputProps={{
            "aria-label": "Telefonnummer",
          }}
        />
      </FormControl>

      <Button
        sx={{
          my: 2,
        }}
        onClick={() => onSubmit()}
      >
        Spara ändringar
      </Button>
      <Button
        sx={{
          my: 2,
        }}
        onClick={() => setEditCompany(company)}
      >
        Ångra ändringar
      </Button>

      <Button sx={{ my: 2 }} onClick={() => setDialogOpen(true)}>
        Radera företag
      </Button>
    </Box>
  );
};

export default EditTab;
