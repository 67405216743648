import { useState, useEffect } from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import logo from "../images/logo.png";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";

import {
  Collapse,
  Fade,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  SwipeableDrawer,
} from "@mui/material";
import useAuth from "../hooks/auth";
import { useNavigate, useLocation } from "react-router-dom";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import {
  unAuthedPages,
  adminPages,
  moderatorPages,
  calculationRoutes,
  calendarRoutes,
} from "../pages/routesTypes";
import { UserRole } from "../api/users/usersTypes";

interface SubMenuOpen {
  id: number;
  open: boolean;
}

interface Anchors {
  id: number;
  anchor: HTMLElement | null;
}

const Navbar = () => {
  const [open, setOpen] = useState(false);
  const [openSubMenusDrawer, setOpenSubMenusDrawer] = useState<SubMenuOpen[]>(
    []
  );
  const [openSubMenusNav, setOpenSubMenusNav] = useState<SubMenuOpen[]>([]);
  const [pages, setPages] = useState(unAuthedPages);
  const [anchorAccount, setAnchorAccount] = useState<null | HTMLElement>(null);
  const [pageAnchors, setPageAnchors] = useState<Anchors[]>([]);

  const handleAccount = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorAccount(event.currentTarget);
  };
  const handlePageAnchors = (
    id: number,
    event: React.MouseEvent<HTMLElement>
  ) => {
    setPageAnchors(
      pageAnchors.map((anchor) =>
        anchor.id === id ? { ...anchor, anchor: event.currentTarget } : anchor
      )
    );
  };

  const getPageAnchor = (id: number): HTMLElement | null => {
    const anchor = pageAnchors.find((anchor) => anchor.id === id);

    if (!anchor) {
      return null;
    }

    return anchor?.anchor;
  };

  const handleClose = () => {
    setAnchorAccount(null);
    setPageAnchors(pageAnchors.map((anchor) => ({ ...anchor, anchor: null })));
  };

  const auth = useAuth();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (auth.authed) {
      if (auth.me.role === UserRole.ADMIN) {
        setPages([...calendarRoutes, ...adminPages, ...calculationRoutes]);
      } else if (auth.me.role === UserRole.MODERATOR) {
        setPages([...calendarRoutes, ...moderatorPages, ...calculationRoutes]);
      } else {
        setPages([...calendarRoutes, ...calculationRoutes]);
      }
    } else {
      if (location.pathname === "/") {
        setPages(unAuthedPages);
      } else {
        setPages([]);
      }
    }
  }, [auth.authed, auth.me]);

  useEffect(() => {
    const subMenusOpen: SubMenuOpen[] = [];
    const anchors: Anchors[] = [];

    pages.forEach((page) => {
      if (page.isDropdown) {
        subMenusOpen.push({ id: page.id, open: false });
        anchors.push({ id: page.id, anchor: null });
      }
    });

    setOpenSubMenusNav(subMenusOpen);
    setOpenSubMenusDrawer(subMenusOpen);
    setPageAnchors(anchors);
  }, [pages]);

  const getSubMenuOpen = (type: "drawer" | "nav", id: number): boolean => {
    if (type === "drawer") {
      const sub = openSubMenusDrawer.find((subMenu) => subMenu.id === id);

      if (!sub) {
        return false;
      }

      return sub.open;
    } else {
      const sub = openSubMenusNav.find((subMenu) => subMenu.id === id);

      if (!sub) {
        return false;
      }

      return sub.open;
    }
  };

  const changeSubMenuOpen = (
    type: "drawer" | "nav",
    id: number,
    value: boolean
  ) => {
    if (type === "drawer") {
      const subs = openSubMenusDrawer.map((sub) => {
        if (sub.id === id) {
          sub.open = value;
        }

        return sub;
      });

      setOpenSubMenusDrawer(subs);
    } else {
      const subs = openSubMenusNav.map((sub) => {
        if (sub.id === id) {
          sub.open = value;
        }

        return sub;
      });

      setOpenSubMenusNav(subs);
    }
  };

  return (
    <AppBar position="static" sx={{ zIndex: 999 }}>
      <Container maxWidth={false}>
        <SwipeableDrawer
          anchor="left"
          open={open}
          onClose={() => setOpen(false)}
          onOpen={() => setOpen(true)}
          sx={{
            px: "1rem",
          }}
        >
          <List>
            {pages.map((page) =>
              page.isDropdown ? (
                <>
                  <ListItemButton
                    onClick={() =>
                      changeSubMenuOpen(
                        "drawer",
                        page.id,
                        !getSubMenuOpen("drawer", page.id)
                      )
                    }
                  >
                    <ListItemIcon>{page.icon}</ListItemIcon>
                    <ListItemText primary={page.label} />
                    {getSubMenuOpen("drawer", page.id) ? (
                      <ExpandLessIcon />
                    ) : (
                      <ExpandMoreIcon />
                    )}
                  </ListItemButton>
                  <Collapse
                    in={getSubMenuOpen("drawer", page.id)}
                    timeout="auto"
                    unmountOnExit
                  >
                    <List component="div" disablePadding>
                      {page.dropdownItems?.map((item) => (
                        <ListItemButton
                          sx={{ pl: 4 }}
                          onClick={() => navigate(item.value)}
                        >
                          <ListItemIcon>{item.icon}</ListItemIcon>
                          <ListItemText primary={item.label} />
                        </ListItemButton>
                      ))}
                    </List>
                  </Collapse>
                </>
              ) : (
                <ListItem key={page.label} disablePadding>
                  <ListItemButton
                    onClick={() => {
                      if (page.isId) {
                        setOpen(false);
                        const interval = setInterval(() => {
                          document
                            .getElementById(page.value)
                            ?.scrollIntoView({ behavior: "smooth" });
                          clearInterval(interval);
                        }, 100);
                      } else {
                        navigate(page.value);
                      }
                    }}
                  >
                    <ListItemIcon>{page.icon}</ListItemIcon>
                    <ListItemText primary={page.label} />
                  </ListItemButton>
                </ListItem>
              )
            )}
          </List>
        </SwipeableDrawer>
        <Toolbar disableGutters>
          <Box
            onClick={() => navigate("/")}
            component="img"
            sx={{
              height: "50px",
              display: {
                xs: "none",
                sm: "none",
                md: "block",
              },
              mr: "20px",
              cursor: "pointer",
            }}
            src={logo}
            alt="logo"
          />

          {location.pathname !== "/login" ? (
            <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={() => setOpen(true)}
                color="inherit"
              >
                <MenuIcon />
              </IconButton>
              <Box
                onClick={() => navigate("/")}
                component="img"
                sx={{
                  height: "50px",
                  display: {
                    xs: "block",
                    sm: "block",
                    md: "none",
                  },
                  mr: "auto",
                  ml: "auto",
                  cursor: "pointer",
                }}
                src={logo}
                alt="logo"
              />
            </Box>
          ) : (
            <Box
              onClick={() => navigate("/")}
              component="img"
              sx={{
                height: "50px",
                display: {
                  xs: "block",
                  sm: "block",
                  md: "none",
                },
                mr: "auto",
                ml: "auto",
                cursor: "pointer",
              }}
              src={logo}
              alt="logo"
            />
          )}

          <Box
            sx={{
              display: {
                xs: "none",
                sm: "none",
                md: "flex",
              },
              flexGrow: 1,
            }}
          ></Box>

          <Box sx={{ flexGrow: 0, display: { xs: "none", md: "flex" } }}>
            {!(
              location.pathname === "/forgot-password" ||
              location.pathname === "/login"
            ) &&
              pages.map((page) => (
                <>
                  {page.isDropdown ? (
                    <>
                      <Button
                        id="fade-button"
                        aria-controls={open ? "fade-menu" : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? "true" : undefined}
                        onClick={(event) => handlePageAnchors(page.id, event)}
                        size="small"
                        sx={{
                          fontSize: "1rem",
                        }}
                        endIcon={<KeyboardArrowDownIcon />}
                      >
                        {page.label}
                      </Button>
                      <Menu
                        id="fade-menu"
                        MenuListProps={{
                          "aria-labelledby": "fade-button",
                        }}
                        anchorEl={getPageAnchor(page.id)}
                        open={Boolean(getPageAnchor(page.id))}
                        onClose={handleClose}
                        TransitionComponent={Fade}
                        anchorOrigin={{
                          vertical: "bottom",
                          horizontal: "center",
                        }}
                        transformOrigin={{
                          vertical: "top",
                          horizontal: "center",
                        }}
                      >
                        {page.dropdownItems?.map((item) => (
                          <MenuItem
                            onClick={() => {
                              handleClose();
                              navigate(item.value);
                            }}
                          >
                            {item.label}
                          </MenuItem>
                        ))}
                      </Menu>
                    </>
                  ) : (
                    <Button
                      key={page.label}
                      sx={{
                        my: 2,
                        color: "primary.light",
                        display: "block",
                        fontSize: "1rem",
                      }}
                      onClick={() => {
                        if (page.isId) {
                          document
                            .getElementById(page.value)
                            ?.scrollIntoView({ behavior: "smooth" });
                        } else {
                          navigate(page.value);
                        }
                      }}
                    >
                      {page.label}
                    </Button>
                  )}
                </>
              ))}
          </Box>

          {location.pathname !== "/login" &&
          location.pathname !== "forgot-password" ? (
            <Box
              sx={{
                display: { xs: "none", md: "block" },
                border: "1px solid #FFFFFC",
                height: "100%",
                width: "1px",
                ml: 1,
              }}
            ></Box>
          ) : null}

          <Box sx={{ flexGrow: 0 }}>
            {location.pathname !== "/login" ? (
              !auth.authed ? (
                <Button
                  sx={{
                    fontSize: "1.2rem",
                    ml: "5px",
                    transform: "scale(0.8)",
                  }}
                  onClick={() => navigate("/login")}
                  variant="outlined"
                  color="primary"
                  size="small"
                  disableElevation
                >
                  Logga in
                </Button>
              ) : (
                <>
                  <Button
                    id="fade-button"
                    aria-controls={open ? "fade-menu" : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? "true" : undefined}
                    onClick={handleAccount}
                    size="small"
                    variant="outlined"
                    sx={{
                      fontSize: "1.2rem",
                      ml: "5px",
                      transform: "scale(0.8)",
                    }}
                    endIcon={<KeyboardArrowDownIcon />}
                  >
                    Konto
                  </Button>
                  <Menu
                    id="fade-menu"
                    MenuListProps={{
                      "aria-labelledby": "fade-button",
                    }}
                    anchorEl={anchorAccount}
                    open={Boolean(anchorAccount)}
                    onClose={handleClose}
                    TransitionComponent={Fade}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "center",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "center",
                    }}
                  >
                    <MenuItem
                      onClick={() => {
                        handleClose();
                        navigate("/installningar/" + auth.me.id);
                      }}
                    >
                      Inställningar
                    </MenuItem>
                    <MenuItem
                      onClick={() => {
                        handleClose();
                        auth.logout();
                      }}
                    >
                      Logga ut
                    </MenuItem>
                  </Menu>
                </>
              )
            ) : null}
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
};

export default Navbar;
