import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import React from "react";

export interface YesNoModalProps {
  open: boolean;
  close: (agreed: boolean) => void;
  title: string;
  description: string;
}

const YesNoModal: React.FC<YesNoModalProps> = ({
  open,
  close,
  title,
  description,
}) => {
  return (
    <Dialog
      open={open}
      onClose={() => {}}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {description}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => close(false)}>Nej</Button>
        <Button onClick={() => close(true)} autoFocus>
          Ja
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default YesNoModal;
