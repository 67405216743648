import {
  Avatar,
  Button,
  Card,
  CardActions,
  CardContent,
  Container,
  List,
  ListItem,
  ListItemText,
  Rating,
  TextField,
  Typography,
  ListItemIcon
} from "@mui/material";
import { Box } from "@mui/system";
import frontPagePC from "../../images/pcFrontPage.png";
import frontpageCalculation from "../../images/frontpageCalculation.jpg";
import frontpagePhone from "../../images/frontpagePhone.png";
import graph from "../../images/graf.svg";
import { Padding } from "@mui/icons-material";
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CancelIcon from '@mui/icons-material/Cancel';
import { TRUE } from "sass";;

interface ListProps {
  text: string;
  checked?: boolean;
}

const ListItemCenter = ({ text, checked }: ListProps) => (
  <ListItem
    sx={{
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      flexDirection: "row",
    }}
  >
    <ListItemText
      sx={{ textAlign: "left", fontSize: "1.5em" }}
      disableTypography
      primary={text}
    />

<ListItemIcon sx={{minWidth:"0px"}}>
    {checked ? (
      <CheckBoxIcon sx={{color: "success.main", fontSize:"2.5em"}}></CheckBoxIcon>
    ) : (
      <CancelIcon sx={{color: "error.main", fontSize:"2.5em"}}></CancelIcon>
    )}
    </ListItemIcon>
  </ListItem>
);

const Home = () => {
  return (
    <Container maxWidth={false} disableGutters>
      <Box
        sx={{
          height: "calc(100vh - 50px)",
          width: "100%",
          display: "flex",
          flexDirection: {
            xs: "column",
            md: "row",
          },
          justifyContent: "space-between",
          alignItems: "end",
          backgroundColor: "primary.main",
        }}
      >
        <Box
          sx={{
            textAlign: "center",
            boxSizing: "border-box",
            paddingLeft: {
              xs: "1rem",
              md: "2rem",
            },
            paddingRight: {
              xs: "1rem",
              md: "0",
            },
            height: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Typography
            color="primary.light"
            variant="h1"
            sx={{
              fontWeight: "bold",
              fontSize: {
                xs: "2.5em",
                md: "5em",
              },
              mb: "20px",
            }}
          >
            Slipp konsulterna, med BisCalc!
          </Typography>

          <Typography
            color="primary.light"
            variant="h5"
            sx={{ mb: "10px", px: "3rem" }}
          >
            Vi utvecklar webbverktyg inom VVS för små till stora företag, klicka
            på knappen nedan för att se mer.
          </Typography>

          <Button
            size="large"
            variant="contained"
            color="primary"
            sx={{
              "&:hover": {
                transform: "scale(1.05)",
                backgroundColor: "primary.light",
                color: "primary.main",
              },
            }}
            onClick={() => {
              document
                .getElementById("product")
                ?.scrollIntoView({ behavior: "smooth" });
            }}
          >
            Se mer
          </Button>
        </Box>

        <Box
          component="img"
          src={frontPagePC}
          alt="Image of computer with biscalc software running"
          sx={{
            width: {
              xs: "100%",
              md: "50%",
            },
          }}
        ></Box>
      </Box>

      <Box
        id="product"
        sx={{
          height: "auto",
          width: "100%",
          mt: "5rem",
          display: "flex",
          flexDirection: {
            xs: "column",
            md: "row",
          },
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            width: {
              xs: "100%",
              md: "50%",
            },
            padding: "1rem",
            boxSizing: "border-box",
          }}
        >
          <Box
            component="img"
            src={frontpageCalculation}
            alt="Bild på beräkningssystem"
            sx={{
              width: "100%",
            }}
          ></Box>
        </Box>

        <Box
          sx={{
            width: {
              xs: "100%",
              md: "50%",
            },
            height: "auto",
            padding: "1rem",
            boxSizing: "border-box",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Typography
            variant="body1"
            sx={{
              fontSize: {
                xs: "1.5em",
                md: "2em",
              },
              textAlign: {
                xs: "center",
                md: "left",
              },
            }}
          >
            BisCalc är ett företag som har målet att förenkla för mindre samt
            medelstora företag genom att utveckla ett verktyg som skall göra det
            dagliga arbetet smidigare samt spara arbetstid. Detta har vi uppnått
            genom att ta fram webbaserade verktyg som har utvecklats med hjälp
            av samlad kunskap av 25 års erfarenhet inom VVS. Produkten
            innehåller beräkningssystem gällande flöde, dimensionering samt
            effektbehov och mer. Allt är skapat utifrån ett pedagogiskt
            tankesätt. Produkten kan även användas på alla enheter som har
            internetuppkoppling, designat för att kunna användas på alla
            arbetsplatser.
          </Typography>
        </Box>
      </Box>

      <Box
        sx={{
          height: "auto",
          width: "100%",
          mt: "5rem",
          display: "flex",
          flexDirection: {
            xs: "column",
            md: "row",
          },
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            width: "50%",
            padding: "1rem",
            boxSizing: "border-box",
            display: {
              xs: "none",
              md: "flex",
            },
          }}
        >
          <Typography variant="body1" sx={{ fontSize: "2em" }}>
            BisCalc är ett företag som har målet att förenkla för mindre samt
            medelstora företag genom att utveckla ett verktyg som skall göra det
            dagliga arbetet smidigare samt spara arbetstid. Detta har vi uppnått
            genom att ta fram webbaserade verktyg som har utvecklats med hjälp
            av samlad kunskap av 25 års erfarenhet inom VVS. Produkten
            innehåller beräkningssystem gällande flöde, dimensionering samt
            effektbehov och mer. Allt är skapat utifrån ett pedagogiskt
            tankesätt. Produkten kan även användas på alla enheter som har
            internetuppkoppling, designat för att kunna användas på alla
            arbetsplatser.
          </Typography>
        </Box>

        <Box
          sx={{
            width: {
              xs: "100%",
              md: "50%",
            },
            height: "auto",
            padding: "1rem",
            boxSizing: "border-box",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Box
            component="img"
            src={graph}
            alt="Bild på beräkningssystem"
            sx={{
              width: "100%",
            }}
          ></Box>
        </Box>

        <Box
          sx={{
            width: "100%",
            padding: "1rem",
            boxSizing: "border-box",
            display: {
              xs: "flex",
              md: "none",
            },
          }}
        >
          <Typography
            variant="body1"
            sx={{ fontSize: "1.5em", textAlign: "center" }}
          >
            BisCalc är ett företag som har målet att förenkla för mindre samt
            medelstora företag genom att utveckla ett verktyg som skall göra det
            dagliga arbetet smidigare samt spara arbetstid. Detta har vi uppnått
            genom att ta fram webbaserade verktyg som har utvecklats med hjälp
            av samlad kunskap av 25 års erfarenhet inom VVS. Produkten
            innehåller beräkningssystem gällande flöde, dimensionering samt
            effektbehov och mer. Allt är skapat utifrån ett pedagogiskt
            tankesätt. Produkten kan även användas på alla enheter som har
            internetuppkoppling, designat för att kunna användas på alla
            arbetsplatser.
          </Typography>
        </Box>
      </Box>

      <Box
        sx={{
          width: {
            xs: "100%",
            md: "100%",
          },
          height: "auto",
          padding: "1rem",
          paddingBottom: "0",
          boxSizing: "border-box",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: {
            xs: "column",
            md: "row",
          },
        }}
      >
        <Box
          sx={{
            width: {
              xs: "100%",
              md: "50%",
            },
            boxSizing: "border-box",
          }}
        >
          <Box
            component="img"
            src={frontpagePhone}
            alt="Bild på beräkningssystem"
            sx={{
              width: "100%",
              mb: "-4px",
            }}
          ></Box>
        </Box>

        <Box
          sx={{
            width: {
              xs: "100%",
              md: "50%",
            },
            height: "auto",
            padding: "1rem",
            boxSizing: "border-box",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Typography
            variant="body1"
            sx={{
              fontSize: {
                xs: "1.5em",
                md: "2em",
              },
              textAlign: {
                xs: "center",
                md: "left",
              },
            }}
          >
            BisCalc är ett företag som har målet att förenkla för mindre samt
            medelstora företag genom att utveckla ett verktyg som skall göra det
            dagliga arbetet smidigare samt spara arbetstid. Detta har vi uppnått
            genom att ta fram webbaserade verktyg som har utvecklats med hjälp
            av samlad kunskap av 25 års erfarenhet inom VVS. Produkten
            innehåller beräkningssystem gällande flöde, dimensionering samt
            effektbehov och mer. Allt är skapat utifrån ett pedagogiskt
            tankesätt. Produkten kan även användas på alla enheter som har
            internetuppkoppling, designat för att kunna användas på alla
            arbetsplatser.
          </Typography>
        </Box>
      </Box>

      <Box
        id="pricing"
        sx={{
          width: "100%",
          height: "auto",
          padding: "2rem 0",
          backgroundColor: "#840032",
          display: "flex",
          justifyContent: "space-around",
          alignItems: "center",
          flexDirection: {
            xs: "column",
            md: "row",
          },
        }}
      >
        <Card
          sx={{
            width: {
              xs: "90%",
              md: "400px",
            },
            height: "auto",
          }}
        >
          <CardContent sx={{ padding: "0 !important" }}>
            <Typography
              variant="h4"
              component="h2"
              sx={{ margin: "16px auto", textAlign: "center" }}
            >
              Budget
            </Typography>
            <Box
              sx={{
                width: "100%",
                backgroundColor: "#670027",
                padding: "8px 0",
              }}
            >
              <Typography
                variant="h5"
                component="h3"
                color="primary.light"
                sx={{ margin: " auto", textAlign: "center" }}
              >
                249kr/månad
              </Typography>
            </Box>

            <List
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-around",
                alignItems: "center",
              }}
            >
              <ListItemCenter text="Flödesberäkningar" checked />
              <ListItemCenter text="Effektbehovsberäkningar" checked />
              <ListItemCenter text="Enhetsomvandling" checked />
              <ListItemCenter text="Shuntgruppsberäkningar" />
              <ListItemCenter text="Varmvattenberedare" />
              <ListItemCenter text="Teamkalender" />
            </List>

            <CardActions>
              <Button
                sx={{
                  width: "100%",
                  height: "auto",
                  backgroundColor: "#670027",
                  color: "white",

                  "&:hover": {
                    backgroundColor: "#ae1952",
                    color: "white",
                  },
                }}
                size="large"
              >
                Köp
              </Button>
            </CardActions>
          </CardContent>
        </Card>

        <Card
          sx={{
            width: {
              xs: "90%",
              md: "400px",
            },
            height: "auto",
            marginTop: {
              xs: "2rem",
              md: "0",
            },
          }}
        >
          <CardContent sx={{ padding: "0 !important" }}>
            <Typography
              variant="h4"
              component="h2"
              sx={{ margin: "16px auto", textAlign: "center" }}
            >
              Standard
            </Typography>
            <Box
              sx={{
                width: "100%",
                backgroundColor: "#670027",
                padding: "8px 0",
              }}
            >
              <Typography
                variant="h5"
                component="h3"
                color="primary.light"
                sx={{ margin: " auto", textAlign: "center" }}
              >
                299kr/månad
              </Typography>
            </Box>

            <List
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-around",
                alignItems: "center",
              }}
            >
              <ListItemCenter text="Flödesberäkningar" checked />
              <ListItemCenter text="Effektbehovsberäkningar" checked />
              <ListItemCenter text="Enhetsomvandling" checked />
              <ListItemCenter text="Shuntgruppsberäkningar" checked />
              <ListItemCenter text="Varmvattenberedare" checked />
              <ListItemCenter text="Teamkalender" />
            </List>

            <CardActions>
              <Button
                sx={{
                  width: "100%",
                  height: "auto",
                  backgroundColor: "#670027",
                  color: "white",

                  "&:hover": {
                    backgroundColor: "#ae1952",
                    color: "white",
                  },
                }}
                size="large"
              >
                Köp
              </Button>
            </CardActions>
          </CardContent>
        </Card>

        <Card
          sx={{
            width: {
              xs: "90%",
              md: "400px",
            },
            height: "auto",
            marginTop: {
              xs: "2rem",
              md: "0",
            },
          }}
        >
          <CardContent sx={{ padding: "0 !important" }}>
            <Typography
              variant="h4"
              component="h4"
              sx={{ margin: "16px auto", textAlign: "center" }}
            >
              Extra
            </Typography>
            <Box
              sx={{
                width: "100%",
                backgroundColor: "#670027",
                padding: "8px 0",
              }}
            >
              <Typography
                variant="h5"
                component="h5"
                color="primary.light"
                sx={{ margin: " auto", textAlign: "center" }}
              >
                399kr/månad
              </Typography>
            </Box>

            <List
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-around",
                alignItems: "center",
              }}
            >
              <ListItemCenter text="Flödesberäkningar" checked />
              <ListItemCenter text="Effektbehovsberäkningar" checked />
              <ListItemCenter text="Enhetsomvandling" checked />
              <ListItemCenter text="Shuntgruppsberäkningar" checked />
              <ListItemCenter text="Varmvattenberedare" checked />
              <ListItemCenter text="Teamkalender" checked />
            </List>

            <CardActions>
              <Button
                sx={{
                  width: "100%",
                  height: "auto",
                  backgroundColor: "#670027",
                  color: "white",

                  "&:hover": {
                    backgroundColor: "#ae1952",
                    color: "white",
                  },
                }}
                size="large"
              >
                Köp
              </Button>
            </CardActions>
          </CardContent>
        </Card>
      </Box>

      <Box
        sx={{
          width: "100%",
          height: "auto",
        }}
      >
        <Typography
          variant="h2"
          sx={{
            margin: "2rem auto",
            textAlign: "center",
          }}
        >
          Våra recensioner
        </Typography>

        <Box
          sx={{
            width: "100%",
            height: "auto",
            display: "flex",
            flexDirection: {
              xs: "column",
              md: "row",
            },
            justifyContent: "space-evenly",
            alignItems: "center",
            padding: "2rem 0 3rem 0",
          }}
        >
          <Card
            sx={{
              width: 320,
              height: "auto",
              transition: "all 0.1s ease-in-out",
              "&:hover": {
                transform: "scale(1.05)",
              },
            }}
            variant="outlined"
          >
            <CardContent>
              <Avatar
                src="https://avatars.dicebear.com/api/male/test2.svg"
                alt="förnamn efternamn"
                sx={{
                  width: "54px",
                  height: "54px",
                }}
              />

              <Typography
                variant="body1"
                component="p"
                sx={{ mt: "24px", fontStyle: "italic" }}
              >
                "Vi är väldigt nöjda med denna tjänsten på mitt företag för att
                den är bra och fungerar mycket."
              </Typography>

              <Typography
                variant="h5"
                component="h5"
                sx={{ mt: "24px", fontSize: "1.3em" }}
              >
                Förnamn Efternamn
              </Typography>
              <Typography
                variant="h5"
                component="h5"
                sx={{ mt: "8px", fontSize: "1em" }}
              >
                Företag AB
              </Typography>

              <Rating
                name="read-only"
                size="small"
                sx={{ marginTop: "16px", marginLeft: "-3px" }}
                value={4.5}
                precision={0.5}
                readOnly
              />
            </CardContent>
          </Card>

          <Card
            sx={{
              width: 320,
              height: "auto",
              transition: "all 0.1s ease-in-out",
              "&:hover": {
                transform: "scale(1.05)",
              },
              mt: {
                xs: "2rem",
                md: "0",
              },
            }}
            variant="outlined"
          >
            <CardContent>
              <Avatar
                src="https://avatars.dicebear.com/api/male/test2.svg"
                alt="förnamn efternamn"
                sx={{
                  width: "54px",
                  height: "54px",
                }}
              />

              <Typography
                variant="body1"
                component="p"
                sx={{ mt: "24px", fontStyle: "italic" }}
              >
                "Vi är väldigt nöjda med denna tjänsten på mitt företag för att
                den är bra och fungerar mycket."
              </Typography>

              <Typography
                variant="h5"
                component="h5"
                sx={{ mt: "24px", fontSize: "1.3em" }}
              >
                Förnamn Efternamn
              </Typography>
              <Typography
                variant="h5"
                component="h5"
                sx={{ mt: "8px", fontSize: "1em" }}
              >
                Företag AB
              </Typography>

              <Rating
                name="read-only"
                size="small"
                sx={{ marginTop: "16px", marginLeft: "-3px" }}
                value={4.5}
                precision={0.5}
                readOnly
              />
            </CardContent>
          </Card>

          <Card
            sx={{
              width: 320,
              height: "auto",
              transition: "all 0.1s ease-in-out",
              "&:hover": {
                transform: "scale(1.05)",
              },
              mt: {
                xs: "2rem",
                md: "0",
              },
            }}
            variant="outlined"
          >
            <CardContent>
              <Avatar
                src="https://avatars.dicebear.com/api/male/test2.svg"
                alt="förnamn efternamn"
                sx={{
                  width: "54px",
                  height: "54px",
                }}
              />

              <Typography
                variant="body1"
                component="p"
                sx={{ mt: "24px", fontStyle: "italic" }}
              >
                "Vi är väldigt nöjda med denna tjänsten på mitt företag för att
                den är bra och fungerar mycket."
              </Typography>

              <Typography
                variant="h5"
                component="h5"
                sx={{ mt: "24px", fontSize: "1.3em" }}
              >
                Förnamn Efternamn
              </Typography>
              <Typography
                variant="h5"
                component="h5"
                sx={{ mt: "8px", fontSize: "1em" }}
              >
                Företag AB
              </Typography>

              <Rating
                name="read-only"
                size="small"
                sx={{ marginTop: "16px", marginLeft: "-3px" }}
                value={4.5}
                precision={0.5}
                readOnly
              />
            </CardContent>
          </Card>
        </Box>
      </Box>

      <Box
        id="contactUs"
        sx={{
          width: "100%",
          height: "auto",
          padding: "3rem 0",
          backgroundColor: "primary.main",
        }}
      >
        <Typography
          variant="h2"
          sx={{
            textAlign: "center",
            color: "primary.light",
          }}
        >
          Kontakta oss
        </Typography>
      </Box>

      <Box
        sx={{
          width: "100%",
          height: "auto",
          padding: "3rem 0",
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            width: "auto",
            height: "auto",
            padding: "2rem",
          }}
        >
          <Typography
            variant="h4"
            sx={{
              color: "primary.main",
              mb: "0.5rem",
            }}
          >
            Skicka email
          </Typography>

          <TextField
            required
            id="nameInput"
            label="Namn"
            placeholder="Förnamn Efternamn"
            size="medium"
            fullWidth
            sx={{
              mb: "1rem",
            }}
          />
          <TextField
            required
            id="emailInput"
            label="E-post adress"
            placeholder="E-post adress"
            size="medium"
            fullWidth
            sx={{
              mb: "1rem",
            }}
          />
          <TextField
            required
            id="reasonInput"
            label="Anledning"
            multiline
            fullWidth
            rows={5}
            variant="outlined"
            placeholder="Anledning till kontakt"
            sx={{
              mb: "1rem",
            }}
          />

          <Button
            variant="contained"
            color="secondary"
            size="large"
            sx={{
              "&:focus": {
                backgroundColor: "primary.main",
              },

              "&:active": {
                backgroundColor: "primary.main",
              },
            }}
          >
            Skicka
          </Button>
        </Box>
      </Box>

      <Box
        sx={{
          width: "100%",
          height: "auto",
          padding: "1rem 0",
          backgroundColor: "primary.main",
        }}
      >
        <Typography
          variant="h5"
          sx={{
            textAlign: "center",
            color: "primary.light",
          }}
        >
          Copyright © 2020 BisCalc HB
        </Typography>
      </Box>
    </Container>
  );
};

export default Home;
