import { Box, Container, Typography } from "@mui/material";
import React from "react";

export interface FormPageLayoutProps {
  title: string;
}

const FormPageLayout: React.FC<FormPageLayoutProps> = ({ title, children }) => {
  return (
    <Container maxWidth={false} disableGutters>
      <Box
        sx={{
          width: "100%",
          height: "100px",
          backgroundColor: "primary.main",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Typography
          variant="h4"
          sx={{
            color: "primary.light",
          }}
        >
          {title}
        </Typography>
      </Box>

      <Container
        maxWidth={"md"}
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          mb: 5,
        }}
      >
        {children}
      </Container>
    </Container>
  );
};

export default FormPageLayout;
