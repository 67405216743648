import { createTheme } from "@mui/material/styles";

export const blue = "#0A1045";
export const blueDark = "#080c33";
export const white = "#FFFFFC";

const theme = createTheme({
  palette: {
    background: {
      default: blue,
      paper: white,
    },
    primary: {
      main: blue,
      dark: blueDark,
      light: white,
    },
    secondary: {
      main: white,
      dark: blueDark,
      light: blue,
    },
    error: {
      main: "#d9534f",
    },
    success: {
      main: "#4BB543",
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          color: white,
          backgroundColor: blue,

          "&:hover": {
            color: blue,
            backgroundColor: white,
          },
        },
        contained: {
          color: blue,
          backgroundColor: white,

          "&:hover": {
            color: white,
            backgroundColor: blue,
          },
        },
        containedPrimary: {
          color: blue,
          backgroundColor: white,

          "&:hover": {
            color: white,
            backgroundColor: blue,
            boxShadow: "none",
          },
        },
        containedSecondary: {
          color: white,
          backgroundColor: blue,
        },
        disabled: {
          color: white,
          backgroundColor: blue,
        },
        outlined: {
          color: white,
          borderColor: white,
        },
        outlinedPrimary: {
          color: white,
          borderColor: white,

          "&:hover": {
            color: white,
            backgroundColor: blueDark,
            borderColor: white,
          },
        },
        outlinedSecondary: {
          color: white,
          borderColor: white,
        },
        text: {
          color: white,
        },
        textPrimary: {
          color: white,
        },
        textSecondary: {
          color: white,
        },
      },
    },
  },
});

export default theme;
