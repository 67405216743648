import { Dimensions, TemperatureDifference } from "./sharedTypes";

export const getNeededEffect = (
  flow: number,
  temperatureDifference: TemperatureDifference,
  isPerSecond: boolean
): number => {
  const multiplier = isPerSecond ? 1 : 3600;

  let result = (4180 * temperatureDifference * flow) / multiplier;

  if (result !== Math.round(result)) {
    result = Number(result.toFixed(2));
  }

  return result;
};

export const getNeededEffectDimensions = (
  neededEffect: number,
  temperatureDifference: TemperatureDifference
): Dimensions => {
  let dimensions: Dimensions = {
    pex: "",
    copper: "",
  };

  switch (temperatureDifference) {
    case 5:
      // --------------------------      pex RÖR dimensioner för W med 5 tempSkillnad       --------------------------------------------
      if (neededEffect <= 170) {
        dimensions.pex = "12x2,0";
      } else if (neededEffect <= 418 && neededEffect > 170) {
        dimensions.pex = "16x2,0";
      } else if (neededEffect <= 1254 && neededEffect > 418) {
        dimensions.pex = "20x2,0";
      } else if (neededEffect <= 2508 && neededEffect > 1254) {
        dimensions.pex = "25x2,3";
      } else if (neededEffect <= 5225 && neededEffect > 2508) {
        dimensions.pex = "32x2,9";
      } else if (neededEffect <= 9823 && neededEffect > 5225) {
        dimensions.pex = "40x3,7";
      } else if (neededEffect <= 15675 && neededEffect > 9823) {
        dimensions.pex = "50x4,6";
      } else if (neededEffect <= 31350 && neededEffect > 15675) {
        dimensions.pex = "63x5,7";
      } else if (neededEffect <= 52250 && neededEffect > 31350) {
        dimensions.pex = "75x6,8";
      } else if (neededEffect <= 83600 && neededEffect > 52250) {
        dimensions.pex = "90x8,2";
      } else if (neededEffect <= 146300 && neededEffect > 83600) {
        dimensions.pex = "110x10,0";
      }
      // ----------------------------------------------------------------------------------------------------------

      // --------------------------      KOPPAR RÖR dimensioner för W med 5 tempskillnad    --------------------------------------------

      if (neededEffect <= 355) {
        dimensions.copper = "12x1,0";
      } else if (neededEffect <= 790 && neededEffect > 355) {
        dimensions.copper = "15x1,0";
      } else if (neededEffect <= 1358 && neededEffect > 790) {
        dimensions.copper = "18x1,0";
      } else if (neededEffect <= 2508 && neededEffect > 1358) {
        dimensions.copper = "22x1,0";
      } else if (neededEffect <= 4598 && neededEffect > 2508) {
        dimensions.copper = "28x1,2";
      } else if (neededEffect <= 8360 && neededEffect > 4598) {
        dimensions.copper = "35x1,5";
      } else if (neededEffect <= 14630 && neededEffect > 8360) {
        dimensions.copper = "42x1,5";
      } else if (neededEffect <= 29260 && neededEffect > 14630) {
        dimensions.copper = "54x1,5";
      } else if (neededEffect <= 56430 && neededEffect > 29260) {
        dimensions.copper = "70x2,0";
      } else if (neededEffect <= 106590 && neededEffect > 56430) {
        dimensions.copper = "76,1x2,0";
      }

      // ----------------------------------------------------------------------------------------------------------

      break;

    case 10:
      // --------------------------      pex RÖR dimensioner för W med 10 tempSkillnad       --------------------------------------------
      if (neededEffect <= 335) {
        dimensions.pex = "12x2,0";
      } else if (neededEffect <= 836 && neededEffect > 335) {
        dimensions.pex = "16x2,0";
      } else if (neededEffect <= 2508 && neededEffect > 836) {
        dimensions.pex = "20x2,0";
      } else if (neededEffect <= 5016 && neededEffect > 2508) {
        dimensions.pex = "25x2,3";
      } else if (neededEffect <= 10450 && neededEffect > 5016) {
        dimensions.pex = "32x2,9";
      } else if (neededEffect <= 19646 && neededEffect > 10450) {
        dimensions.pex = "40x3,7";
      } else if (neededEffect <= 31350 && neededEffect > 19646) {
        dimensions.pex = "50x4,6";
      } else if (neededEffect <= 62700 && neededEffect > 31350) {
        dimensions.pex = "63x5,7";
      } else if (neededEffect <= 104500 && neededEffect > 62700) {
        dimensions.pex = "75x6,8";
      } else if (neededEffect <= 167200 && neededEffect > 104500) {
        dimensions.pex = "90x8,2";
      } else if (neededEffect <= 292600 && neededEffect > 167200) {
        dimensions.pex = "110x10,0";
      }
      // ----------------------------------------------------------------------------------------------------------

      // --------------------------      KOPPAR RÖR dimensioner för W med 10 tempskillnad    --------------------------------------------

      if (neededEffect <= 710) {
        dimensions.copper = "12x1,0";
      } else if (neededEffect <= 1588 && neededEffect > 710) {
        dimensions.copper = "15x1,0";
      } else if (neededEffect <= 2717 && neededEffect > 1588) {
        dimensions.copper = "18x1,0";
      } else if (neededEffect <= 5016 && neededEffect > 2717) {
        dimensions.copper = "22x1,0";
      } else if (neededEffect <= 9196 && neededEffect > 5016) {
        dimensions.copper = "28x1,2";
      } else if (neededEffect <= 16720 && neededEffect > 9196) {
        dimensions.copper = "35x1,5";
      } else if (neededEffect <= 29260 && neededEffect > 16720) {
        dimensions.copper = "42x1,5";
      } else if (neededEffect <= 58520 && neededEffect > 29260) {
        dimensions.copper = "54x1,5";
      } else if (neededEffect <= 112860 && neededEffect > 58520) {
        dimensions.copper = "70x2,0";
      } else if (neededEffect <= 213180 && neededEffect > 112860) {
        dimensions.copper = "76,1x2,0";
      }

      // ----------------------------------------------------------------------------------------------------------

      break;
    case 15:
      // --------------------------      pex RÖR dimensioner för W med 15 tempSkillnad       --------------------------------------------
      if (neededEffect <= 502) {
        dimensions.pex = "12x2,0";
      } else if (neededEffect <= 1254 && neededEffect > 502) {
        dimensions.pex = "16x2,0";
      } else if (neededEffect <= 3762 && neededEffect > 1254) {
        dimensions.pex = "20x2,0";
      } else if (neededEffect <= 7524 && neededEffect > 3762) {
        dimensions.pex = "25x2,3";
      } else if (neededEffect <= 15675 && neededEffect > 7524) {
        dimensions.pex = "32x2,9";
      } else if (neededEffect <= 29469 && neededEffect > 15675) {
        dimensions.pex = "40x3,7";
      } else if (neededEffect <= 47025 && neededEffect > 29469) {
        dimensions.pex = "50x4,6";
      } else if (neededEffect <= 94050 && neededEffect > 47025) {
        dimensions.pex = "63x5,7";
      } else if (neededEffect <= 156750 && neededEffect > 94050) {
        dimensions.pex = "75x6,8";
      } else if (neededEffect <= 250800 && neededEffect > 156750) {
        dimensions.pex = "90x8,2";
      } else if (neededEffect <= 438900 && neededEffect > 250800) {
        dimensions.pex = "110x10,0";
      }
      // ----------------------------------------------------------------------------------------------------------

      // --------------------------      KOPPAR RÖR dimensioner för W med 15 tempskillnad    --------------------------------------------

      if (neededEffect <= 1066) {
        dimensions.copper = "12x1,0";
      } else if (neededEffect <= 2380 && neededEffect > 1066) {
        dimensions.copper = "15x1,0";
      } else if (neededEffect <= 4075 && neededEffect > 2380) {
        dimensions.copper = "18x1,0";
      } else if (neededEffect <= 7524 && neededEffect > 4075) {
        dimensions.copper = "22x1,0";
      } else if (neededEffect <= 13794 && neededEffect > 7524) {
        dimensions.copper = "28x1,2";
      } else if (neededEffect <= 25080 && neededEffect > 13794) {
        dimensions.copper = "35x1,5";
      } else if (neededEffect <= 43890 && neededEffect > 25080) {
        dimensions.copper = "42x1,5";
      } else if (neededEffect <= 87780 && neededEffect > 43890) {
        dimensions.copper = "54x1,5";
      } else if (neededEffect <= 169290 && neededEffect > 87780) {
        dimensions.copper = "70x2,0";
      } else if (neededEffect <= 319770 && neededEffect > 169290) {
        dimensions.copper = "76,1x2,0";
      }

      // ----------------------------------------------------------------------------------------------------------

      break;
    case 20:
      // --------------------------      pex RÖR dimensioner för W med 20 tempSkillnad       --------------------------------------------
      if (neededEffect <= 670) {
        dimensions.pex = "12x2,0";
      } else if (neededEffect <= 1672 && neededEffect > 670) {
        dimensions.pex = "16x2,0";
      } else if (neededEffect <= 5016 && neededEffect > 1672) {
        dimensions.pex = "20x2,0";
      } else if (neededEffect <= 10032 && neededEffect > 5016) {
        dimensions.pex = "25x2,3";
      } else if (neededEffect <= 20900 && neededEffect > 10032) {
        dimensions.pex = "32x2,9";
      } else if (neededEffect <= 39292 && neededEffect > 20900) {
        dimensions.pex = "40x3,7";
      } else if (neededEffect <= 62700 && neededEffect > 39292) {
        dimensions.pex = "50x4,6";
      } else if (neededEffect <= 125400 && neededEffect > 62700) {
        dimensions.pex = "63x5,7";
      } else if (neededEffect <= 209000 && neededEffect > 125400) {
        dimensions.pex = "75x6,8";
      } else if (neededEffect <= 334400 && neededEffect > 209000) {
        dimensions.pex = "90x8,2";
      } else if (neededEffect <= 585200 && neededEffect > 334400) {
        dimensions.pex = "110x10,0";
      }
      // ----------------------------------------------------------------------------------------------------------

      // --------------------------      KOPPAR RÖR dimensioner för W med 20 tempskillnad    --------------------------------------------

      if (neededEffect <= 1420) {
        dimensions.copper = "12x1,0";
      } else if (neededEffect <= 3177 && neededEffect > 1420) {
        dimensions.copper = "15x1,0";
      } else if (neededEffect <= 5434 && neededEffect > 3177) {
        dimensions.copper = "18x1,0";
      } else if (neededEffect <= 10032 && neededEffect > 5434) {
        dimensions.copper = "22x1,0";
      } else if (neededEffect <= 18392 && neededEffect > 10032) {
        dimensions.copper = "28x1,2";
      } else if (neededEffect <= 33440 && neededEffect > 18392) {
        dimensions.copper = "35x1,5";
      } else if (neededEffect <= 58520 && neededEffect > 33440) {
        dimensions.copper = "42x1,5";
      } else if (neededEffect <= 117040 && neededEffect > 58520) {
        dimensions.copper = "54x1,5";
      } else if (neededEffect <= 225720 && neededEffect > 117040) {
        dimensions.copper = "70x2,0";
      } else if (neededEffect <= 426360 && neededEffect > 225720) {
        dimensions.copper = "76,1x2,0";
      }

      // ----------------------------------------------------------------------------------------------------------

      break;
  }
  return dimensions;
};
