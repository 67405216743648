import {
  Alert,
  Box,
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormHelperText,
  MenuItem,
  OutlinedInput,
  Snackbar,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import CompaniesController from "../../api/companies/companies";
import { Company } from "../../api/companies/companiesTypes";
import UsersController from "../../api/users/users";
import {
  adminRoles,
  roles,
  User,
  UserByIdDTO,
  UserRole,
} from "../../api/users/usersTypes";
import ErrorDisplay from "../../components/errorDisplay";
import FormPageLayout from "../../components/formPageLayout";
import useAuth from "../../hooks/auth";

const Settings = () => {
  const { id } = useParams();
  const auth = useAuth();
  const navigate = useNavigate();
  const usersController = new UsersController(auth.getToken());
  const companiesController = new CompaniesController(auth.getToken());

  const [user, setUser] = useState<User>({
    id: "",
    firstname: "",
    lastname: "",
    companyId: "",
    email: "",
    updatedAt: new Date(),
    createdAt: new Date(),
    role: UserRole.USER,
  });
  const [password, setPassword] = useState<string>("");
  const [passwordRe, setPasswordRe] = useState<string>("");
  const [companies, setCompanies] = useState<Company[]>([]);
  const [refresh, setRefresh] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);

  useEffect(() => {
    if (!auth.loading) {
      getUser();
      getCompanies();
    }
  }, [refresh, auth.loading]);

  const isValidPassword = (): Boolean => {
    return password.length >= 6;
  };

  const getUser = async () => {
    if (id === null) return;

    const dto = await usersController.getUser(id || "");

    if (dto.isError) {
      setError(dto.statusText);

      return;
    }

    if (dto.data !== null) setUser(dto.data);
  };

  const getCompanies = async () => {
    const dto = await companiesController.getAllCompanies();

    if (dto.isError) {
      setError(dto.statusText);

      return;
    }

    setCompanies(dto.data);
  };

  const handleChange = (key: string, value: any) => {
    setUser({
      ...user,
      [key]: value,
    });
  };

  const updateUser = async () => {
    if (!id) {
      setError("Felaktig användare");

      return;
    }

    let dto: UserByIdDTO;

    if (password.length !== 0) {
      if (password !== passwordRe && !isValidPassword()) {
        setError("Lösenorden matchar inte eller så är det för kort");

        return;
      }
      dto = await usersController.updateUser(id, { ...user, password });
    } else {
      dto = await usersController.updateUser(id, user);
    }

    if (dto.isError) {
      setError(dto.statusText);

      return;
    }

    if (dto.data !== null) setUser(dto.data);
  };

  const handlePasswordChange = (isOriginal: boolean, value: string) => {
    if (isOriginal) {
      setPassword(value);
    } else {
      setPasswordRe(value);
    }
  };

  const removeUser = async () => {
    if (!id) {
      setError("Felaktig användare");

      return;
    }

    const dto = await usersController.deleteUser(id);

    if (dto.isError) {
      setError(dto.statusText);

      return;
    }

    navigate(-1);
  };

  return (
    <FormPageLayout title="Kontoinställningar">
      <ErrorDisplay error={error} setError={setError}></ErrorDisplay>

      <Dialog
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Radera användare</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Är du säker på att du vill radera {user.firstname} {user.lastname}?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDialogOpen(false)}>Avbryt</Button>
          <Button onClick={() => removeUser()} autoFocus>
            Radera
          </Button>
        </DialogActions>
      </Dialog>

      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: {
            xs: "column",
            sm: "column",
            md: "row",
          },
        }}
      >
        <FormControl sx={{ m: 1, width: "25ch" }} variant="outlined">
          <FormHelperText
            id="firstname-text"
            sx={{
              fontSize: { xs: "0.8rem", sm: "1rem", md: "1.2rem" },
              color: "primary.main",
              mx: 1,
              mt: 3,
            }}
          >
            Förnamn
          </FormHelperText>
          <OutlinedInput
            id="firstname-input"
            value={user.firstname}
            onChange={(event) => {
              handleChange("firstname", event.target.value);
            }}
            aria-describedby="firstname-text"
            inputProps={{
              "aria-label": "förnamn",
            }}
          />
        </FormControl>

        <FormControl sx={{ m: 1, width: "25ch" }} variant="outlined">
          <FormHelperText
            id="lastname-text"
            sx={{
              fontSize: { xs: "0.8rem", sm: "1rem", md: "1.2rem" },
              color: "primary.main",
              mx: 1,
              mt: 3,
            }}
          >
            Efternamn
          </FormHelperText>
          <OutlinedInput
            id="lastname-input"
            value={user.lastname}
            onChange={(event) => {
              handleChange("lastname", event.target.value);
            }}
            aria-describedby="lastname-text"
            inputProps={{
              "aria-label": "efternamn",
            }}
          />
        </FormControl>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: {
            xs: "column",
            sm: "column",
            md: "row",
          },
        }}
      >
        <FormControl sx={{ m: 1, width: "25ch" }} variant="outlined">
          <FormHelperText
            id="company-text"
            sx={{
              fontSize: { xs: "0.8rem", sm: "1rem", md: "1.2rem" },
              color: "primary.main",
              mx: 1,
            }}
          >
            Företag
          </FormHelperText>
          <TextField
            id="company-input"
            value={user.companyId}
            onChange={(event) => handleChange("companyId", event.target.value)}
            aria-describedby="company-text"
            inputProps={{
              "aria-label": "företag",
            }}
            select
          >
            {companies.map((company) => (
              <MenuItem key={company.id} value={company.id}>
                {company.name}
              </MenuItem>
            ))}
          </TextField>
        </FormControl>

        <FormControl sx={{ m: 1, width: "25ch" }} variant="outlined">
          <FormHelperText
            id="role-text"
            sx={{
              fontSize: { xs: "0.8rem", sm: "1rem", md: "1.2rem" },
              color: "primary.main",
              mx: 1,
            }}
          >
            Roll
          </FormHelperText>
          <TextField
            id="role-input"
            value={user.role}
            onChange={(event) => handleChange("role", event.target.value)}
            aria-describedby="role-text"
            inputProps={{
              "aria-label": "roll",
            }}
            select
          >
            {(auth.me.role === UserRole.ADMIN ? adminRoles : roles).map(
              (role) => (
                <MenuItem key={role.value} value={role.value}>
                  {role.label}
                </MenuItem>
              )
            )}
          </TextField>
        </FormControl>
      </Box>
      <FormControl
        sx={{ width: { xs: "25ch", sm: "25ch", md: "52ch" }, mt: 1 }}
        variant="outlined"
      >
        <FormHelperText
          id="email-text"
          sx={{
            fontSize: { xs: "0.8rem", sm: "1rem", md: "1.2rem" },
            color: "primary.main",
            mx: 1,
          }}
        >
          Email
        </FormHelperText>
        <OutlinedInput
          id="email-input"
          type="email"
          value={user.email}
          inputProps={{
            pattern:
              "/^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:.[a-zA-Z0-9-]+)*$/",
            "aria-label": "email",
          }}
          required
          onChange={(event) => {
            handleChange("email", event.target.value);
          }}
          aria-describedby="email-text"
        />
      </FormControl>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: {
            xs: "column",
            md: "row",
          },
        }}
      >
        <FormControl sx={{ m: 1, width: "25ch" }} variant="outlined">
          <FormHelperText
            id="password-text"
            sx={{
              fontSize: { xs: "0.8rem", sm: "1rem", md: "1.2rem" },
              color: "primary.main",
              mx: 1,
              mt: 3,
            }}
          >
            Nytt lösenord
          </FormHelperText>
          <OutlinedInput
            id="password-input"
            type="password"
            value={password}
            onChange={(event) => {
              handlePasswordChange(true, event.target.value);
            }}
            aria-describedby="password-text"
            inputProps={{
              "aria-label": "nytt lösenord",
            }}
          />
        </FormControl>

        <FormControl sx={{ m: 1, width: "25ch" }} variant="outlined">
          <FormHelperText
            id="passwordRe-text"
            sx={{
              fontSize: { xs: "0.8rem", sm: "1rem", md: "1.2rem" },
              color: "primary.main",
              mx: 1,
              mt: 3,
            }}
          >
            Repetera lösenordet
          </FormHelperText>
          <OutlinedInput
            id="passwordRe-input"
            value={passwordRe}
            type="password"
            onChange={(event) => {
              handlePasswordChange(false, event.target.value);
            }}
            aria-describedby="passwordRe-text"
            inputProps={{
              "aria-label": "Skriv nya lösenordet igen",
            }}
          />
        </FormControl>
      </Box>
      <Button
        sx={{
          my: 3,
        }}
        onClick={() => updateUser()}
      >
        Spara ändringar
      </Button>
      <Button
        sx={{
          mb: 5,
        }}
        onClick={() => setDialogOpen(true)}
      >
        Ta bort användare
      </Button>
    </FormPageLayout>
  );
};

export default Settings;
