import { AxiosError } from "axios";
import Controller from "../api";
import {
  AllCompaniesDTO,
  CreateUpdateCompany,
  DeleteCompanyDTO,
  GetCompanyDTO,
} from "./companiesTypes";

export default class CompaniesController extends Controller {
  public async getAllCompanies(): Promise<AllCompaniesDTO> {
    const response = await this.get("/companies");

    if (Controller.isGoodResponse(response.status)) {
      return { ...response, isError: false } as AllCompaniesDTO;
    } else {
      let error = response as AxiosError;
      return {
        status: error.response?.status || 0,
        statusText: error.response?.statusText || "",
        data: [],
        isError: true,
      };
    }
  }

  public async getCompany(id: string): Promise<GetCompanyDTO> {
    console.log(id);
    const response = await this.get("/companies/" + id);

    if (Controller.isGoodResponse(response.status)) {
      return { ...response, isError: false } as GetCompanyDTO;
    } else {
      let error = response as AxiosError;
      console.log(error);
      return {
        status: error.response?.status || 0,
        statusText: error.response?.statusText || "",
        data: null,
        isError: true,
      };
    }
  }

  public async createCompany(
    companyDetails: CreateUpdateCompany
  ): Promise<GetCompanyDTO> {
    const response = await this.post("/companies", companyDetails);

    if (Controller.isGoodResponse(response.status)) {
      return { ...response, isError: false } as GetCompanyDTO;
    } else {
      let error = response as AxiosError;
      return {
        status: error.response?.status || 0,
        statusText: ((error.response?.data as any).message as string) || "",
        data: null,
        isError: true,
      };
    }
  }

  public async updateCompany(
    id: string,
    companyDetails: CreateUpdateCompany
  ): Promise<GetCompanyDTO> {
    const response = await this.put("/companies/" + id, companyDetails);

    if (Controller.isGoodResponse(response.status)) {
      return { ...response, isError: false } as GetCompanyDTO;
    } else {
      let error = response as AxiosError;
      return {
        status: error.response?.status || 0,
        statusText: error.response?.statusText || "",
        data: null,
        isError: true,
      };
    }
  }

  public async deleteCompany(id: string): Promise<DeleteCompanyDTO> {
    const response = await this.delete("/companies/" + id);

    if (Controller.isGoodResponse(response.status)) {
      return {
        status: response.status,
        statusText: response.status,
        data: null,
        isError: false,
      } as DeleteCompanyDTO;
    } else {
      let error = response as AxiosError;
      return {
        status: error.response?.status || 0,
        statusText: error.response?.statusText || "",
        data: null,
        isError: true,
      };
    }
  }
}
