import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  MenuItem,
  Modal,
  OutlinedInput,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { Company } from "../../api/companies/companiesTypes";
import {
  adminRoles,
  CreateUser,
  roles,
  RoleText,
  UserRole,
} from "../../api/users/usersTypes";
import useAuth from "../../hooks/auth";

export interface CreateUserModalProps {
  open: boolean;
  companies: Company[];
  handleClose: () => void;
  createUser: (user: CreateUser) => void;
}

const CreateUserModal: React.FC<CreateUserModalProps> = ({
  open,
  handleClose,
  createUser,
  companies,
}) => {
  const auth = useAuth();

  const [user, setUser] = useState<CreateUser>({
    firstname: "",
    lastname: "",
    role: UserRole.USER,
    email: "",
    companyId: auth.me.companyId,
    password: "",
  });

  const handleChange = (key: string, value: any) => {
    setUser({
      ...user,
      [key]: value,
    });
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      sx={{
        overflow: "scroll",
      }}
    >
      <Box
        sx={{
          zIndex: 10,
          position: "absolute" as "absolute",
          left: "50%",
          transform: "translateX(-50%)",
          width: {
            xs: 250,
            sm: 350,
            md: 500,
          },
          bgcolor: "background.paper",
          boxShadow: 24,
          border: 0,
          borderRadius: "10px",
          p: 4,

          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <Typography
          id="modal-modal-title"
          variant="h6"
          component="h2"
          sx={{
            mx: "auto",
            textAlign: "center",
          }}
        >
          Skapa ny användare
        </Typography>

        <FormControl sx={{ m: 1, width: "25ch" }} variant="outlined">
          <FormHelperText
            id="firstname-text"
            sx={{
              fontSize: { xs: "0.8rem", sm: "1rem", md: "1.2rem" },
              color: "primary.main",
              mx: 1,
              mt: 3,
            }}
          >
            Förnamn
          </FormHelperText>
          <OutlinedInput
            id="firstname-input"
            value={user.firstname}
            onChange={(event) => {
              handleChange("firstname", event.target.value);
            }}
            aria-describedby="firstname-text"
            inputProps={{
              "aria-label": "förnamn",
            }}
          />
        </FormControl>

        <FormControl sx={{ m: 1, width: "25ch" }} variant="outlined">
          <FormHelperText
            id="lastname-text"
            sx={{
              fontSize: { xs: "0.8rem", sm: "1rem", md: "1.2rem" },
              color: "primary.main",
              mx: 1,
            }}
          >
            Efternamn
          </FormHelperText>
          <OutlinedInput
            id="lastname-input"
            value={user.lastname}
            onChange={(event) => {
              handleChange("lastname", event.target.value);
            }}
            aria-describedby="lastname-text"
            inputProps={{
              "aria-label": "efternamn",
            }}
          />
        </FormControl>

        <FormControl sx={{ m: 1, width: "25ch" }} variant="outlined">
          <FormHelperText
            id="email-text"
            sx={{
              fontSize: { xs: "0.8rem", sm: "1rem", md: "1.2rem" },
              color: "primary.main",
              mx: 1,
            }}
          >
            Email
          </FormHelperText>
          <OutlinedInput
            id="email-input"
            type="email"
            value={user.email}
            inputProps={{
              pattern:
                "/^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:.[a-zA-Z0-9-]+)*$/",
              "aria-label": "email",
            }}
            required
            onChange={(event) => {
              handleChange("email", event.target.value);
            }}
            aria-describedby="email-text"
          />
        </FormControl>

        <FormControl sx={{ m: 1, width: "25ch" }} variant="outlined">
          <FormHelperText
            id="company-text"
            sx={{
              fontSize: { xs: "0.8rem", sm: "1rem", md: "1.2rem" },
              color: "primary.main",
              mx: 1,
            }}
          >
            Företag
          </FormHelperText>
          <TextField
            id="company-input"
            value={user.companyId}
            onChange={(event) => handleChange("companyId", event.target.value)}
            aria-describedby="company-text"
            inputProps={{
              "aria-label": "företag",
            }}
            select
          >
            {companies.map((company) => (
              <MenuItem key={company.id} value={company.id}>
                {company.name}
              </MenuItem>
            ))}
          </TextField>
        </FormControl>

        <FormControl sx={{ m: 1, width: "25ch" }} variant="outlined">
          <FormHelperText
            id="role-text"
            sx={{
              fontSize: { xs: "0.8rem", sm: "1rem", md: "1.2rem" },
              color: "primary.main",
              mx: 1,
            }}
          >
            Roll
          </FormHelperText>
          <TextField
            id="role-input"
            value={user.role}
            onChange={(event) => handleChange("role", event.target.value)}
            aria-describedby="role-text"
            inputProps={{
              "aria-label": "roll",
            }}
            select
          >
            {(auth.me.role === UserRole.ADMIN ? adminRoles : roles).map(
              (role) => (
                <MenuItem key={role.value} value={role.value}>
                  {role.label}
                </MenuItem>
              )
            )}
          </TextField>
        </FormControl>

        <FormControl sx={{ m: 1, width: "25ch" }} variant="outlined">
          <FormHelperText
            id="password-text"
            sx={{
              fontSize: { xs: "0.8rem", sm: "1rem", md: "1.2rem" },
              color: "primary.main",
              mx: 1,
            }}
          >
            Lösenord
          </FormHelperText>
          <OutlinedInput
            id="password-input"
            type="password"
            value={user.password}
            onChange={(event) => {
              handleChange("password", event.target.value);
            }}
            aria-describedby="password-text"
            inputProps={{
              "aria-label": "lösenord",
            }}
          />
        </FormControl>

        <Box
          sx={{
            my: 3,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: {
              xs: "column",
              sm: "column",
              md: "row",
            },
          }}
        >
          <Button
            sx={{
              mr: 1,
              my: 1,
            }}
            onClick={() => {
              createUser(user);
              handleClose();
            }}
          >
            Skapa användare
          </Button>
          <Button
            sx={{
              ml: 1,
              my: 1,
            }}
            onClick={() => handleClose()}
          >
            Avbryt
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default CreateUserModal;
