import {
  Alert,
  Box,
  Card,
  CardContent,
  Container,
  setRef,
  Snackbar,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import CompaniesController from "../../../api/companies/companies";
import { Company } from "../../../api/companies/companiesTypes";
import TabPanel from "../../../components/tabPanel";
import InformationTab from "./tabs/InformationTab";
import useAuth from "../../../hooks/auth";
import { useNavigate, useParams } from "react-router-dom";
import EditTab from "./tabs/EditTab";
import { CreateUser } from "../../../api/users/usersTypes";
import { GridCellEditCommitParams } from "@mui/x-data-grid";
import UsersController from "../../../api/users/users";
import AccountsTab from "./tabs/AccountsTab";

const AdminCompany = () => {
  const auth = useAuth();
  const navigate = useNavigate();
  const companiesController = new CompaniesController(auth.getToken());
  const usersController = new UsersController(auth.getToken());
  const { id } = useParams();

  const [tab, setTab] = useState<number>(0);
  const [company, setCompany] = useState<Company>({
    id: "",
    name: "",
    phone: "",
    createdAt: new Date(),
    updatedAt: new Date(),
    users: [],
  });
  const [refresh, setRefresh] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    if (!auth.loading) {
      getCompany();
    }
  }, [refresh, auth.loading]);

  const getCompany = async () => {
    if (!id) {
      setError("Företaget existerar inte");

      return;
    }

    const dto = await companiesController.getCompany(id);

    if (dto.isError) {
      setError(dto.statusText);

      return;
    }

    if (dto.data !== null) setCompany(dto.data);
  };

  const updateCompany = async (editCompany: Company) => {
    if (!id) {
      setError("Företaget existerar inte");

      return;
    }

    const dto = await companiesController.updateCompany(id, editCompany);

    if (dto.isError) {
      setError(dto.statusText);

      return;
    }

    setRefresh(!refresh);
  };

  const createUser = async (user: CreateUser) => {
    const dto = await usersController.createUser(user);

    if (dto.isError) {
      setError(dto.statusText);
      return;
    }

    setRefresh(!refresh);
  };

  const updateUser = async (params: GridCellEditCommitParams) => {
    if (company.users === undefined) {
      return;
    }
    let user = company.users.find((user) => params.id.toString() === user.id);

    if (!user) {
      return;
    }

    user = {
      ...user,
      [params.field]: params.value,
    };

    await usersController.updateUser(params.id.toString(), user);

    setRefresh(!refresh);
  };

  const deleteCompany = async () => {
    if (!id) {
      setError("Företaget existerar inte ");
      return;
    }

    const dto = await companiesController.deleteCompany(id);

    if (dto.isError) {
      setError(dto.statusText);

      return;
    }

    navigate(-1);
  };

  return (
    <Container maxWidth={"lg"}>
      <Snackbar
        open={error !== null}
        autoHideDuration={6000}
        onClose={() => {
          setError(null);
        }}
      >
        <Alert severity="error" sx={{ width: "100%" }}>
          {error}
        </Alert>
      </Snackbar>

      <Typography
        variant="h2"
        sx={{
          mt: 4,
          mb: 1,
          fontWeight: "bold",
          mx: "auto",
          textAlign: "center",
        }}
      >
        {company.name}
      </Typography>

      <Box
        sx={{
          mt: 5,
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <Box
          sx={{
            width: "100%",
            borderBottom: 1,
            borderColor: "divider",
          }}
        >
          <Tabs value={tab} onChange={(_event, value) => setTab(value)}>
            <Tab
              value={0}
              label="Information"
              id={"tab-0"}
              aria-controls={"tabpanel-0"}
            ></Tab>
            <Tab
              value={1}
              label="Redigera"
              id={"tab-1"}
              aria-controls={"tabpanel-1"}
            ></Tab>
            <Tab
              value={2}
              label="Konton"
              id={"tab-2"}
              aria-controls={"tabpanel-2"}
            ></Tab>
          </Tabs>
        </Box>

        <TabPanel index={0} value={tab}>
          <InformationTab company={company}></InformationTab>
        </TabPanel>
        <TabPanel index={1} value={tab}>
          <EditTab
            company={company}
            updateCompany={updateCompany}
            deleteCompany={deleteCompany}
          ></EditTab>
        </TabPanel>
        <TabPanel index={2} value={tab}>
          <AccountsTab
            company={company}
            updateUser={updateUser}
            createUser={createUser}
          ></AccountsTab>
        </TabPanel>
      </Box>
    </Container>
  );
};

export default AdminCompany;
