import axios from "axios";
import React, { createContext, useContext, useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import AuthController from "../api/auth/auth";
import { LoginType } from "../api/auth/authTypes";
import UsersController from "../api/users/users";
import { User, UserRole } from "../api/users/usersTypes";

const useAuth = () => {
  const [authed, setAuthed] = useState(localStorage.getItem("token") !== null);
  const [token, setToken] = useState(localStorage.getItem("token"));
  const [loading, setLoading] = useState(true);
  const [me, setMe] = useState<User>({
    id: "",
    email: "",
    role: UserRole.USER,
    firstname: "",
    lastname: "",
    createdAt: new Date(),
    updatedAt: new Date(),
  } as User);

  const navigate = useNavigate();
  const [params, setParams] = useSearchParams();
  const authController = new AuthController(token);
  const usersController = new UsersController(token);

  useEffect(() => {
    if (params.get("token") !== null) {
      //typescript sucks
      addToken(params.get("token") || "");
    }
  }, [params]);

  useEffect(() => {
    authController.setToken(token);
    usersController.setToken(token);
    getMe();
  }, [token]);

  if (token !== null) {
    axios.defaults.headers.common["Authorization"] = "Bearer " + token;
  }

  const getMe = async () => {
    if (token !== null) {
      await usersController.getUser("me").then((res) => {
        if (!res.isError) {
          if (res.data !== null) {
            setMe(res.data);
            setLoading(false);

            return;
          }
        }

        setToken(null);
      });
    }
  };

  const addToken = (token: string): void => {
    localStorage.setItem("token", token);
    setToken(token);
    axios.defaults.headers.common["Authorization"] = "Bearer " + token;
  };

  const removeToken = (): void => {
    localStorage.removeItem("token");
    setToken(null);
    axios.defaults.headers.common["Authorization"] = "";
  };

  return {
    authed,
    me,
    loading,
    async login({
      email,
      password,
      stayLoggedIn,
    }: LoginType): Promise<Boolean> {
      const response = await authController.login({
        email,
        password,
        stayLoggedIn,
      });

      console.log(response);

      if (response.isError) {
        return false;
      } else {
        addToken(response.data.token);
        setAuthed(true);
        navigate("/");
        return true;
      }
    },
    logout() {
      removeToken();
      setAuthed(false);
      navigate("/");
    },
    getToken() {
      if (params.get("token") !== null) {
        return params.get("token");
      } else {
        return token;
      }
    },
  };
};

export interface AuthProviderProps {
  children: React.ReactNode;
}

const defaultData = {
  authed: false,
  loading: true,
  me: {
    id: "",
    email: "",
    role: UserRole.USER,
    firstname: "",
    lastname: "",
    createdAt: new Date(),
    updatedAt: new Date(),
  } as User,
  login: async ({
    email,
    password,
    stayLoggedIn,
  }: LoginType): Promise<Boolean> => {
    return false;
  },
  logout: () => {},
  getToken: (): string | null => {
    return "";
  },
};

const authContext = createContext(defaultData);

export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const auth = useAuth();

  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
};

const AuthConsumer = () => {
  return useContext(authContext);
};

export default AuthConsumer;
