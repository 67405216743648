import { Alert, Box, Button, Container, Snackbar } from "@mui/material";
import {
  DataGrid,
  GridCellEditCommitParams,
  GridColDef,
  GridColumns,
  GridToolbar,
  svSE,
} from "@mui/x-data-grid";
import { formatRelative } from "date-fns";
import { sv } from "date-fns/locale";
import { useEffect, useState } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import CompaniesController from "../../../api/companies/companies";
import { Company } from "../../../api/companies/companiesTypes";
import UsersController from "../../../api/users/users";
import { CreateUser, User, UserRole } from "../../../api/users/usersTypes";
import CreateUserModal from "../../../components/modals/createUser";
import useAuth from "../../../hooks/auth";
import { RoleText } from "../../../api/users/usersTypes";

const AdminUsers = () => {
  const navigate = useNavigate();
  const auth = useAuth();
  const usersController = new UsersController(auth.getToken());
  const companiesController = new CompaniesController(auth.getToken());

  const [refresh, setRefresh] = useState<boolean>(false);
  const [users, setUsers] = useState<User[]>([]);
  const [companies, setCompanies] = useState<Company[]>([]);
  const [error, setError] = useState<string | null>(null);
  const [modalOpen, setModalOpen] = useState<boolean>(false);

  const columns: GridColDef[] = [
    { field: "id", headerName: "ID", width: 150 },
    {
      field: "firstname",
      headerName: "Förnamn",
      width: 150,
      editable: true,
    },
    {
      field: "lastname",
      headerName: "Efternamn",
      width: 150,
      editable: true,
    },
    {
      field: "email",
      headerName: "Email",
      width: 150,
      editable: true,
    },
    {
      field: "role",
      headerName: "Roll",
      width: 150,
      valueGetter(params) {
        return RoleText[params.row.role as UserRole];
      },
    },
    {
      field: "companyId",
      headerName: "Företag",
      width: 150,
      valueGetter(cell) {
        const company = companies.find((company) => company.id === cell.value);

        if (company) {
          return company.name;
        } else {
          return "Hittades inte";
        }
      },
    },
    {
      field: "updatedAt",
      headerName: "Senast uppdaterad",
      width: 150,
      valueFormatter(params) {
        return formatRelative(new Date(params.value), new Date(), {
          locale: sv,
        });
      },
    },
    {
      field: "createdAt",
      headerName: "Skapad",
      width: 150,
      valueFormatter(params) {
        return formatRelative(new Date(params.value), new Date(), {
          locale: sv,
        });
      },
    },
    {
      field: "",
      headerName: "",
      width: 150,
      renderCell(cell) {
        return (
          <Button onClick={() => navigate("/admin/anvandare/" + cell.row.id)}>
            Se mer
          </Button>
        );
      },
    },
  ];

  useEffect(() => {
    if (!auth.loading) {
      getUsers();
      getCompanies();
    }
  }, [refresh, auth.loading]);

  const getUsers = async () => {
    const dto = await usersController.getAllUsers();

    if (dto.isError) {
      setError(dto.statusText);
      return;
    }

    setUsers(dto.data);
  };

  const getCompanies = async () => {
    const dto = await companiesController.getAllCompanies();

    if (dto.isError) {
      setError(dto.statusText);
      return;
    }

    setCompanies(dto.data);
  };

  const createUser = async (user: CreateUser) => {
    const dto = await usersController.createUser(user);

    if (dto.isError) {
      setError(dto.statusText);
      return;
    }

    setRefresh(!refresh);
  };

  const updateState = async (params: GridCellEditCommitParams) => {
    let user = users.find((user) => params.id.toString() === user.id);

    if (!user) {
      return;
    }

    user = {
      ...user,
      [params.field]: params.value,
    };

    await usersController.updateUser(params.id.toString(), user);

    setRefresh(!refresh);
  };

  return (
    <Container
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      <CreateUserModal
        open={modalOpen}
        handleClose={() => setModalOpen(false)}
        createUser={createUser}
        companies={companies}
      ></CreateUserModal>

      <Snackbar
        open={error !== null}
        autoHideDuration={6000}
        onClose={() => {
          setError(null);
        }}
      >
        <Alert severity="error" sx={{ width: "100%" }}>
          {error}
        </Alert>
      </Snackbar>

      <Button
        sx={{
          mx: "auto",
          my: 4,
        }}
        onClick={() => setModalOpen(true)}
      >
        Lägg till ny användare
      </Button>
      <Box
        sx={{
          width: "100%",
        }}
      >
        <DataGrid
          sx={{
            height: "400px",
            width: "100%",
            "& .MuiDataGrid-toolbarContainer button": {
              height: "24px",
              mr: 1,
            },
          }}
          localeText={svSE.components.MuiDataGrid.defaultProps.localeText}
          rows={users}
          columns={columns}
          components={{ Toolbar: GridToolbar }}
          onCellEditCommit={updateState}
        ></DataGrid>
      </Box>
    </Container>
  );
};

export default AdminUsers;
