import {
  Alert,
  Avatar,
  Box,
  Button,
  Chip,
  Container,
  FormControl,
  FormHelperText,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Snackbar,
  TextField,
  Typography,
} from "@mui/material";
import { DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { useEffect, useState } from "react";
import {
  Appointment,
  AppointmentTag,
  CreateUpdateAppointment,
} from "../../../api/appointments/appointmentTypes";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import useAuth from "../../../hooks/auth";
import UsersController from "../../../api/users/users";
import { Company } from "../../../api/companies/companiesTypes";
import CompaniesController from "../../../api/companies/companies";
import AddIcon from "@mui/icons-material/Add";
import { User, UserRole } from "../../../api/users/usersTypes";
import AppointmentsController from "../../../api/appointments/appointments";
import { findAllByDisplayValue } from "@testing-library/react";
import { StayPrimaryLandscape } from "@mui/icons-material";
import { mainModule } from "process";
import { Navigate } from "react-big-calendar";
import { useNavigate } from "react-router-dom";
import ErrorDisplay from "../../../components/errorDisplay";
import FormPageLayout from "../../../components/formPageLayout";

const CreateAppointment = () => {
  const auth = useAuth();
  const navigate = useNavigate();
  const companiesController = new CompaniesController(auth.getToken());
  const appointmentsController = new AppointmentsController(auth.getToken());

  const [error, setError] = useState<string | null>(null);
  const [refresh, setRefresh] = useState<boolean>(false);
  const [selectableUsers, setSelectableUsers] = useState<User[]>([]);
  const [selectedUser, setSelectedUser] = useState<User>({
    id: "",
    role: UserRole.USER,
    firstname: "",
    lastname: "",
    companyId: "",
    email: "",
    createdAt: new Date(),
    updatedAt: new Date(),
  });
  const [appointment, setAppointment] = useState<CreateUpdateAppointment>({
    title: "",
    description: "",
    company: {
      id: "",
      name: "",
      phone: "",
      createdAt: new Date(),
      updatedAt: new Date(),
    },
    isCompleted: false,
    users: [],
    tag: {
      id: "",
      name: "",
      color: "",
    },
    start: new Date(),
    end: new Date(),
  });
  const [company, setCompany] = useState<Company>({
    id: "",
    name: "",
    phone: "",
    createdAt: new Date(),
    updatedAt: new Date(),
    users: [],
  });
  const [tags, setTags] = useState<AppointmentTag[]>([]);

  useEffect(() => {
    if (!auth.loading) {
      getCompany();
      getTags();
    }
  }, [refresh, auth.loading]);

  useEffect(() => {
    if (company.id !== "" && company.users && company.users?.length > 0) {
      setSelectedUser(company.users[0]);

      setSelectableUsers(company.users);
    }
  }, [company]);

  useEffect(() => {
    if (selectableUsers.length !== 0) {
      setSelectedUser(selectableUsers[0]);
    } else {
      setSelectedUser({
        id: "",
        role: UserRole.USER,
        firstname: "",
        lastname: "",
        companyId: "",
        email: "",
        createdAt: new Date(),
        updatedAt: new Date(),
      });
    }
  }, [selectableUsers]);

  const getCompany = async () => {
    const id = auth.me.companyId;

    const dto = await companiesController.getCompany(id);

    if (dto.isError) {
      setError(dto.statusText);

      return;
    }

    if (dto.data !== null) setCompany(dto.data);
  };

  const getTags = async () => {
    const id = auth.me.companyId;

    const dto = await appointmentsController.getTags(id);

    if (dto.isError) {
      setError(dto.statusText);

      return;
    }

    if (dto.data !== null) setTags(dto.data);
  };

  const addUser = () => {
    if (selectedUser.id !== "") {
      appointment.users.push(selectedUser);
      setSelectableUsers(
        selectableUsers.filter((user) => user !== selectedUser)
      );
      if (selectableUsers.length !== 0) {
        setSelectedUser(selectableUsers[0]);
      } else {
        setSelectedUser({
          id: "",
          role: UserRole.USER,
          firstname: "",
          lastname: "",
          companyId: "",
          email: "",
          createdAt: new Date(),
          updatedAt: new Date(),
        });
      }
    }
  };

  const handleChange = (key: string, value: any) => {
    setAppointment({
      ...appointment,
      [key]: value,
    });
  };

  const handleTagSelect = (event: any) => {
    const id = event.target.value;
    console.log(id);

    const tag = tags.find((findVal) => findVal.id === id);
    console.log(tag);

    if (tag) {
      setAppointment({ ...appointment, tag });
    }
  };

  const handleUserSelect = (event: any) => {
    const id = event.target.value;

    const user = selectableUsers.find((findVal) => findVal.id === id);

    if (user) {
      setSelectedUser(user);
    }
  };

  const handleRemoveUser = (user: User) => {
    setSelectableUsers([...selectableUsers, user]);
    setAppointment({
      ...appointment,
      users: appointment.users.filter((usr) => usr.id !== user.id),
    });
  };

  const CreateAppointment = async () => {
    if (
      appointment.title !== "" &&
      appointment.start < appointment.end &&
      appointment.users.length >= 1
    ) {
      const dto = await appointmentsController.createAppointment(appointment);

      if (dto.isError) {
        setError(dto.statusText);

        return;
      }

      navigate(-1);
    } else {
      setError(
        "Felaktig data, kolla datumets start/sluttid, att användare är tillagda i projektet och att en titel finns"
      );
    }
  };

  return (
    <FormPageLayout title="Skapa bokning">
      <ErrorDisplay error={error} setError={setError}></ErrorDisplay>
      <FormControl
        sx={{
          m: 1,
          width: {
            xs: "25ch",
            md: "52ch",
          },
        }}
        variant="outlined"
      >
        <FormHelperText
          id="name-text"
          sx={{
            fontSize: { xs: "0.8rem", sm: "1rem", md: "1.2rem" },
            color: "primary.main",
            mx: 1,
            mt: 3,
          }}
        >
          Bokningstitel
        </FormHelperText>
        <OutlinedInput
          id="name-input"
          value={appointment.title}
          onChange={(event) => {
            handleChange("title", event.target.value);
          }}
          aria-describedby="name-text"
          inputProps={{
            "aria-label": "Bokningstitel",
          }}
        />
      </FormControl>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-around",
            alignItems: "center",
            flexDirection: {
              xs: "column",
              md: "row",
            },
          }}
        >
          <FormControl sx={{ m: 1, width: "25ch" }} variant="outlined">
            <FormHelperText
              id="start-text"
              sx={{
                fontSize: { xs: "0.8rem", sm: "1rem", md: "1.2rem" },
                color: "primary.main",
                mx: 1,
                mt: 3,
              }}
            >
              Börjar
            </FormHelperText>
            <DateTimePicker
              renderInput={(props) => <TextField {...props} />}
              value={appointment.start}
              onChange={(newValue) => {
                handleChange("start", newValue);
              }}
              ampm={false}
            />
          </FormControl>
          <FormControl sx={{ m: 1, width: "25ch" }} variant="outlined">
            <FormHelperText
              id="end-text"
              sx={{
                fontSize: { xs: "0.8rem", sm: "1rem", md: "1.2rem" },
                color: "primary.main",
                mx: 1,
                mt: 3,
              }}
            >
              Slutar
            </FormHelperText>
            <DateTimePicker
              renderInput={(props) => <TextField {...props} />}
              value={appointment.end}
              onChange={(newValue) => {
                handleChange("end", newValue);
              }}
              ampm={false}
            />
          </FormControl>
        </Box>
      </LocalizationProvider>

      <FormControl
        sx={{
          m: 1,
          width: {
            xs: "25ch",
            md: "52ch",
          },
        }}
        variant="outlined"
      >
        <FormHelperText
          id="description-text"
          sx={{
            fontSize: { xs: "0.8rem", sm: "1rem", md: "1.2rem" },
            color: "primary.main",
            mx: 1,
            mt: 3,
          }}
        >
          Beskrivning
        </FormHelperText>
        <OutlinedInput
          id="description-input"
          multiline
          minRows={4}
          value={appointment.description}
          onChange={(event) => {
            handleChange("description", event.target.value);
          }}
          aria-describedby="description-text"
          inputProps={{
            "aria-label": "Beskrivning",
          }}
        />
      </FormControl>
      <FormControl
        sx={{
          my: 3,
          mx: 1,
          width: {
            xs: "25ch",
            md: "52ch",
          },
        }}
        variant="outlined"
      >
        <FormHelperText
          id="tag-text"
          sx={{
            fontSize: { xs: "0.8rem", sm: "1rem", md: "1.2rem" },
            color: "primary.main",
            mx: 1,
          }}
        >
          Välj tagg
        </FormHelperText>
        <TextField
          id="tag-input"
          value={appointment.tag?.id}
          onChange={handleTagSelect}
          aria-describedby="tag-text"
          inputProps={{
            "aria-label": "tagg",
          }}
          select
        >
          {tags.map((tag) => (
            <MenuItem key={tag.id} value={tag.id}>
              <Chip
                label={tag.name}
                sx={{
                  backgroundColor: tag.color,
                  color: "primary.light",
                  fontSize: "1rem",
                }}
              ></Chip>
            </MenuItem>
          ))}
        </TextField>
      </FormControl>

      <Box
        sx={{
          display: "flex",
          alignItems: "end",
          justifyContent: "start",
          width: {
            xs: "26ch",
            md: "53ch",
          },
        }}
      >
        <FormControl
          sx={{
            m: 1,
            width: {
              xs: "26ch",
              md: "42ch",
            },
            mb: 0,
          }}
          variant="outlined"
        >
          <FormHelperText
            id="users-text"
            sx={{
              fontSize: { xs: "0.8rem", sm: "1rem", md: "1.2rem" },
              color: "primary.main",
              mx: 1,
            }}
          >
            Lägg till användare
          </FormHelperText>
          <TextField
            id="users-input"
            value={selectedUser.id}
            onChange={handleUserSelect}
            aria-describedby="users-text"
            inputProps={{
              "aria-label": "användare",
            }}
            select
          >
            {selectableUsers.map((user) => (
              <MenuItem key={user.id} value={user.id}>
                {user.firstname} {user.lastname} ({user.email})
              </MenuItem>
            ))}
          </TextField>
        </FormControl>
        <Button
          sx={{
            ml: 3,
            height: "56px",
            width: "56px",
          }}
          onClick={() => addUser()}
        >
          <AddIcon fontSize="large"></AddIcon>
        </Button>
      </Box>

      <Typography
        sx={{
          fontSize: { xs: "0.8rem", sm: "1rem", md: "1.2rem" },
          color: "primary.main",
          mx: 1,
          mt: 3,
        }}
      >
        Tillagda användare
      </Typography>
      {appointment.users.length > 0 ? (
        <List
          dense
          sx={{
            width: {
              xs: "25ch",
              md: "52ch",
            },
            m: 1,
            bgcolor: "background.paper",
          }}
        >
          {appointment.users.map((user) => {
            const labelId = `list-label-${user.id}`;
            return (
              <ListItem
                key={user.id}
                secondaryAction={
                  <Button
                    onClick={() => {
                      handleRemoveUser(user);
                    }}
                    sx={{
                      backgroundColor: "error.main",
                    }}
                  >
                    Ta bort
                  </Button>
                }
                disablePadding
              >
                <ListItemButton>
                  <ListItemText
                    id={labelId}
                    primary={user.firstname + user.lastname}
                    secondary={user.email}
                  />
                </ListItemButton>
              </ListItem>
            );
          })}
        </List>
      ) : (
        <Typography
          sx={{
            fontSize: { xs: "0.8rem", sm: "1rem", md: "1.2rem" },
            color: "primary.main",
            mx: 1,
            mt: 3,
          }}
        >
          Inga har lagts till än
        </Typography>
      )}

      <Button
        size="large"
        sx={{
          fontSize: "1.4rem",
          mt: 5,
        }}
        onClick={() => CreateAppointment()}
      >
        Skapa bokning
      </Button>
    </FormPageLayout>
  );
};

export default CreateAppointment;
