import { AxiosError } from "axios";
import Controller from "../api";
import {
  AllUserDTO,
  CreateUser,
  CreateUserDTO,
  DeleteUserDTO,
  UpdateUser,
  User,
  UserByIdDTO,
} from "./usersTypes";

export default class UsersController extends Controller {
  public async getAllUsers(): Promise<AllUserDTO> {
    const response = await this.get("/users");

    if (Controller.isGoodResponse(response.status)) {
      return { ...response, isError: false } as AllUserDTO;
    } else {
      let error = response as AxiosError;
      return {
        status: error.response?.status || 0,
        statusText: error.response?.statusText || "",
        data: [],
        isError: true,
      };
    }
  }

  public async getUser(id: string): Promise<UserByIdDTO> {
    const response = await this.get(`/users/${id}`);

    if (Controller.isGoodResponse(response.status)) {
      return { ...response, isError: false } as UserByIdDTO;
    } else {
      let error = response as AxiosError;
      return {
        status: error.response?.status || 0,
        statusText: error.response?.statusText || "",
        data: null,
        isError: true,
      };
    }
  }

  public async createUser(user: CreateUser): Promise<CreateUserDTO> {
    const response = await this.post("/users", user);

    if (Controller.isGoodResponse(response.status)) {
      return { ...response, isError: false } as CreateUserDTO;
    } else {
      let error = response as AxiosError;
      return {
        status: error.response?.status || 0,
        statusText: error.response?.statusText || "",
        data: null,
        isError: true,
      };
    }
  }

  public async updateUser(id: string, user: UpdateUser): Promise<UserByIdDTO> {
    const response = await this.put("/users/" + id, user);

    if (Controller.isGoodResponse(response.status)) {
      return { ...response, isError: false } as UserByIdDTO;
    } else {
      let error = response as AxiosError;
      return {
        status: error.response?.status || 0,
        statusText: error.response?.statusText || "",
        data: null,
        isError: true,
      };
    }
  }

  public async deleteUser(id: string): Promise<DeleteUserDTO> {
    const response = await this.delete("/users/" + id);

    if (Controller.isGoodResponse(response.status)) {
      return {
        status: response.status,
        statusText: response.status,
        data: null,
        isError: false,
      } as DeleteUserDTO;
    } else {
      let error = response as AxiosError;
      return {
        status: error.response?.status || 0,
        statusText: error.response?.statusText || "",
        data: null,
        isError: true,
      };
    }
  }
}
