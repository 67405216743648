import { LoginDTO, LoginType } from "./authTypes";
import Controller from "../api";
import { AxiosError } from "axios";

export default class AuthController extends Controller {
  public async login({ email, password }: LoginType): Promise<LoginDTO> {
    const response = await this.post("/auth/login", { email, password });

    if (Controller.isGoodResponse(response.status)) {
      return { ...response, isError: false } as LoginDTO;
    } else {
      let error = response as AxiosError;
      return {
        status: error.response?.status || 0,
        statusText: error.response?.statusText || "",
        data: {
          token: "",
        },
        isError: true,
      };
    }
  }
}
