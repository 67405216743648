import { Box, Button, Container, Modal, Typography } from "@mui/material";
import {
  addDays,
  addHours,
  endOfWeek,
  format,
  isSameDay,
  startOfDay,
  startOfWeek,
} from "date-fns";
import moment from "moment";
import { useCallback, useEffect, useRef, useState } from "react";
import {
  Calendar,
  EventPropGetter,
  momentLocalizer,
  View,
  Views,
} from "react-big-calendar";
import withDragAndDrop from "react-big-calendar/lib/addons/dragAndDrop";
import "react-big-calendar/lib/addons/dragAndDrop/styles.scss";
import {
  Appointment,
  CreateUpdateAppointment,
} from "../../../api/appointments/appointmentTypes";
import { User, UserRole } from "../../../api/users/usersTypes";
import useAuth from "../../../hooks/auth";
import CalendarToolbar from "../../../components/calendarToolbar";
import ScheduleController from "../../../api/schedule/schedule";
import AppointmentsController from "../../../api/appointments/appointments";
import { areIntervalsOverlapping, endOfDay } from "date-fns";
import sv from "date-fns/locale/sv";
import { useNavigate } from "react-router-dom";
import QuickUpdateAppointmentModal from "../../../components/modals/quickUpdateAppointment";
import ErrorDisplay from "../../../components/errorDisplay";
import UsersController from "../../../api/users/users";
import CompaniesController from "../../../api/companies/companies";
require("moment/locale/sv.js");
const localizer = momentLocalizer(moment);

//odd error that doesnt do anything that doesnt do anything
//@ts-ignore
const DragAndDropCalendar = withDragAndDrop(Calendar);

interface ResourceData {
  resourceId: string;
}

interface Resource {
  resourceTitle: string;
  resourceId: string;
}

interface ResourceAppointment extends ResourceData, Appointment {}

const MultiCalendarPage = () => {
  const auth = useAuth();
  const navigate = useNavigate();
  const appointmentsController = new AppointmentsController(auth.getToken());
  const companiesController = new CompaniesController(auth.getToken());

  const [date, setDate] = useState<Date>(new Date());
  const [view, setView] = useState<View>("day");
  const [appointments, setAppointments] = useState<Appointment[]>([]);
  const [users, setUsers] = useState<User[]>([]);
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [selectedAppointment, setSelectedAppointment] = useState<string>("");
  const [error, setError] = useState<string | null>(null);
  const [refresh, setRefresh] = useState<boolean>(false);

  useEffect(() => {
    if (!auth.loading) {
      getSchedule();
    }
  }, [date, view, auth.loading, refresh]);

  useEffect(() => {
    if (!auth.loading) {
      getResources();
    }
  }, [auth.loading, refresh]);

  const getSchedule = async () => {
    let start: Date;
    let end: Date;

    if (view === "day") {
      start = startOfDay(date);
      end = endOfDay(date);
    } else {
      start = startOfDay(startOfWeek(date, { weekStartsOn: 1 }));
      end = endOfDay(endOfWeek(date, { weekStartsOn: 1 }));
    }

    const dto = await appointmentsController.getCompanyAppointmentsWithRange(
      auth.me.companyId,
      start,
      end
    );

    if (dto.isError) {
      console.log(dto.statusText);
    }

    dto.data.forEach((appointment) => {
      appointment.start = new Date(appointment.start);
      appointment.end = new Date(appointment.end);
    });

    setAppointments(dto.data);
  };

  const getResources = async () => {
    const dto = await companiesController.getCompany(auth.me.companyId);

    if (dto.isError) {
      setError(dto.statusText);
      return;
    }

    if (dto.data !== null && dto.data.users !== undefined) {
      setUsers(dto.data.users);
    }
  };

  const deleteAppointment = async (id: string) => {
    const dto = await appointmentsController.deleteAppointment(id);

    if (dto.isError) {
      setError(dto.statusText);
      return;
    }

    setAppointments(
      appointments.filter((appointment) => appointment.id !== id)
    );
  };

  const submitAppointmentUpdate = useCallback(
    async (id: string, updateDetails: CreateUpdateAppointment) => {
      const dto = await appointmentsController.updateAppointment(
        id,
        updateDetails
      );

      if (dto.isError) {
        setError(dto.statusText);
        return;
      }

      const appointment = appointments.find((app) => app.id === id);

      if (!appointment) {
        console.log("No appointment found, refreshing");
        setRefresh(!refresh);
        return;
      }

      const otherAppointments = appointments.filter((app) => app.id !== id);

      setAppointments([
        ...otherAppointments,
        {
          ...appointment,
          ...updateDetails,
        },
      ]);
    },
    [appointmentsController, appointments, refresh]
  );

  const getSelectedAppointment = (): Appointment | undefined => {
    return appointments.find((findVal) => findVal.id === selectedAppointment);
  };

  const onSelectEvent = useCallback((calEvent: object) => {
    const appointment = calEvent as Appointment;

    setSelectedAppointment(appointment.id);
    setModalOpen(true);
  }, []);

  const moveAppointment = useCallback(
    ({ event, start, end, resourceId }) => {
      if (resourceId !== event.resourceId) {
        if (event.users.find((user: User) => user.id === resourceId)) {
          setError("Användaren är redan med i bokningen");
          return;
        }

        event.users = event.users.filter((user: User) => {
          return user.id !== event.resourceId;
        });

        const user = users.find((user) => user.id === resourceId);

        if (!user) {
          setError("Användaren hittades inte, ladda om sidan och prova igen");
          return;
        }

        event.users.push(user);
      }

      submitAppointmentUpdate(event.id, { ...event, start, end });
    },
    [submitAppointmentUpdate, users]
  );

  const resizeAppointment = useCallback(
    ({ event, start, end }) => {
      submitAppointmentUpdate(event.id, { ...event, start, end });
    },
    [submitAppointmentUpdate]
  );

  const colorSetter: EventPropGetter<object> = useCallback((event: object) => {
    const appointment = event as Appointment;

    if (!appointment.tag) {
      return {};
    }

    return {
      style: {
        backgroundColor: appointment.tag.color,
      },
    };
  }, []);

  const getFormattedData = (): ResourceAppointment[] => {
    const resourceAppointments: ResourceAppointment[] = [];

    appointments.forEach((appointment) => {
      appointment.users.forEach((user) => {
        resourceAppointments.push({
          ...appointment,
          resourceId: user.id,
        } as ResourceAppointment);
      });
    });

    return resourceAppointments;
  };

  const getResourceMap = (): Resource[] => {
    return users.map((user) => {
      return {
        resourceId: user.id,
        resourceTitle: user.firstname + " " + user.lastname,
      };
    });
  };

  return (
    <Container
      maxWidth={false}
      disableGutters
      sx={{
        p: 3,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <ErrorDisplay error={error} setError={setError}></ErrorDisplay>
      <QuickUpdateAppointmentModal
        open={modalOpen}
        setOpen={setModalOpen}
        appointment={getSelectedAppointment()}
        setError={setError}
        submit={submitAppointmentUpdate}
        deleteAppointment={deleteAppointment}
      />

      <CalendarToolbar
        setDate={setDate}
        setView={setView}
        date={date}
        view={view}
      />

      <DragAndDropCalendar
        style={{
          width: "100%",
        }}
        localizer={localizer}
        events={getFormattedData()}
        resourceIdAccessor={(resource) => {
          const value = resource as Resource;

          return value.resourceId;
        }}
        resourceTitleAccessor={(resource) => {
          const value = resource as Resource;

          return value.resourceTitle;
        }}
        resources={getResourceMap()}
        toolbar={false}
        date={date}
        view={view}
        min={moment("6:00am", "h:mma").toDate()}
        max={moment("6:00pm", "h:mma").toDate()}
        eventPropGetter={colorSetter}
        onSelectEvent={onSelectEvent}
        onEventDrop={moveAppointment}
        onEventResize={resizeAppointment}
        resizable
      ></DragAndDropCalendar>
    </Container>
  );
};

export default MultiCalendarPage;
