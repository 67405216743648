import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Typography,
} from "@mui/material";
import { formatDistance, formatDuration, intervalToDuration } from "date-fns";
import { sv } from "date-fns/locale";
import React from "react";
import { AppointmentTag } from "../../api/appointments/appointmentTypes";
import { capitalizeFirstLetter } from "../../libs/text";

export interface AppointmentCardProps {
  title: string;
  start: Date;
  end: Date;
  tag: AppointmentTag | null;
}

const AppointmentCard: React.FC<AppointmentCardProps> = ({
  title,
  start,
  end,
  tag,
}) => {
  return (
    <Card
      sx={{
        width: {
          xs: "300px",
          md: "400px",
        },
        mb: 3,
      }}
    >
      <Box
        sx={{
          height: "50px",
          width: "100%",
          backgroundColor: tag?.color || "primary.main",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Typography
          sx={{
            color: "primary.light",
          }}
        >
          {tag?.name}
        </Typography>
      </Box>
      <CardContent
        sx={{
          p: 2,
        }}
      >
        <Typography gutterBottom variant="h5" component="div">
          {title}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          {capitalizeFirstLetter(
            formatDistance(new Date(start), new Date(), {
              addSuffix: true,
              locale: sv,
            })
          )}
          <br></br>
          Varar i{" "}
          {formatDuration(
            intervalToDuration({
              start: new Date(start),
              end: new Date(end),
            }),
            {
              locale: sv,
              format: ["years", "months", "weeks", "days", "hours", "minutes"],
              delimiter: ", ",
            }
          )}
        </Typography>
      </CardContent>
      <CardActions>
        <Button
          sx={{
            display: "block",
            width: "100%",
            backgroundColor: "primary.light",
            color: "primary.main",
          }}
        >
          Läs mer
        </Button>
      </CardActions>
    </Card>
  );
};

export default AppointmentCard;
