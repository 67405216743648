import {
  Box,
  Container,
  FormControl,
  FormHelperText,
  InputAdornment,
  MenuItem,
  OutlinedInput,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import {
  getFlow,
  getFlowDimensions,
} from "../../../calculations/flowAndEffect/flow";
import {
  Dimensions,
  TemperatureDifference,
} from "../../../calculations/flowAndEffect/sharedTypes";

const temperatureDifferences = [
  {
    label: "5",
    value: 5,
  },
  {
    label: "10",
    value: 10,
  },
  {
    label: "15",
    value: 15,
  },
  {
    label: "20",
    value: 20,
  },
];

const units = [
  {
    label: "liter/sekund",
    value: 1,
  },
  {
    label: "liter/timme",
    value: 0,
  },
];

interface Values {
  isPerSecond: boolean;
  effect: number;
  temperatureDifference: TemperatureDifference;
}

interface Results {
  flow: number;
  dimensions: Dimensions;
}

const FlowCalculation = () => {
  const [values, setValues] = useState<Values>({
    isPerSecond: false,
    effect: 0,
    temperatureDifference: 20,
  });

  const [results, setResults] = useState<Results>({
    flow: 2,
    dimensions: {
      pex: "90x8,2",
      copper: "90x8,2",
    },
  });

  useEffect(() => {
    const flow = getFlow(
      values.effect,
      values.temperatureDifference,
      values.isPerSecond
    );

    const dimensions = getFlowDimensions(flow, values.isPerSecond);

    setResults({
      flow,
      dimensions,
    });
  }, [values]);

  const handleChange = (
    key: string,
    value: boolean | number | TemperatureDifference
  ) => {
    setValues({
      ...values,
      [key]: value,
    });
  };

  return (
    <>
      <Container maxWidth={false}>
        <Typography
          variant="h3"
          sx={{
            mx: "auto",
            mb: 3,
            mt: 5,
            textAlign: "center",
          }}
        >
          Beräkna flöde
        </Typography>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <FormControl sx={{ m: 1, width: "25ch" }} variant="outlined">
            <FormHelperText
              id="flow-text"
              sx={{
                fontSize: { xs: "0.8rem", sm: "1rem", md: "1.2rem" },
                color: "primary.main",
                mx: 1,
              }}
            >
              Effekt
            </FormHelperText>
            <OutlinedInput
              id="flow-input"
              value={values.effect}
              type="number"
              onChange={(event) => {
                handleChange("effect", Number(event.target.value));
              }}
              endAdornment={
                <InputAdornment position="end">Watt</InputAdornment>
              }
              aria-describedby="flow-text"
              inputProps={{
                "aria-label": "Flow",
              }}
            />
          </FormControl>
          <FormControl sx={{ m: 1, width: "25ch" }} variant="outlined">
            <FormHelperText
              id="temperature-difference-text"
              sx={{
                fontSize: { xs: "0.8rem", sm: "1rem", md: "1.2rem" },
                color: "primary.main",
                mx: 1,
              }}
            >
              Temperaturskillnad
            </FormHelperText>
            <TextField
              id="temperatureDifference-input"
              value={values.temperatureDifference}
              onChange={(event) =>
                handleChange(
                  "temperatureDifference",
                  Number(event.target.value)
                )
              }
              aria-describedby="temperature-difference-text"
              inputProps={{
                "aria-label": "Temperature difference",
              }}
              select
            >
              {temperatureDifferences.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {"\u0394"}
                  {option.label} {" \u00b0"}C
                </MenuItem>
              ))}
            </TextField>
          </FormControl>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <FormControl sx={{ width: "52ch" }} variant="outlined">
            <FormHelperText
              id="isPerSecond-difference-text"
              sx={{
                fontSize: { xs: "0.8rem", sm: "1rem", md: "1.2rem" },
                color: "primary.main",
                mx: 1,
              }}
            >
              Enhet
            </FormHelperText>
            <TextField
              id="isPerSecond-input"
              value={Number(values.isPerSecond)}
              onChange={(event) =>
                handleChange("isPerSecond", !!Number(event.target.value))
              }
              aria-describedby="isPerSecond-difference-text"
              inputProps={{
                "aria-label": "Unit",
              }}
              select
            >
              {units.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </FormControl>
        </Box>

        <Typography
          variant="h4"
          sx={{
            mx: "auto",
            mb: 3,
            mt: 5,
            textAlign: "center",
          }}
        >
          Flöde
        </Typography>
        <Typography
          variant="h5"
          sx={{
            mx: "auto",
            textAlign: "center",
          }}
        >
          {results.flow} {values.isPerSecond ? "liter/sekund" : "liter/timme"}
        </Typography>

        <Typography
          variant="h4"
          sx={{
            mx: "auto",
            textAlign: "center",
            mt: 5,
          }}
        >
          Dimensioner
        </Typography>

        <Box
          sx={{
            mx: "auto",
            display: "flex",
            flexDirection: {
              xs: "column",
              sm: "column",
              md: "row",
            },
            justifyContent: "center",
            alignItems: "center",
            my: 3,
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              mr: {
                xs: 0,
                sm: 0,
                md: 6,
              },
            }}
          >
            <Typography variant="h5">PEX</Typography>
            <Typography variant="h6">{results.dimensions.pex}</Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <Typography variant="h5">Koppar</Typography>
            <Typography variant="h6">{results.dimensions.copper}</Typography>
          </Box>
        </Box>
      </Container>
    </>
  );
};

export default FlowCalculation;
