import AirIcon from "@mui/icons-material/Air";
import BoltIcon from "@mui/icons-material/Bolt";
import CompressIcon from "@mui/icons-material/Compress";
import CalendarMonthTwoToneIcon from "@mui/icons-material/CalendarMonthTwoTone";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import PaymentIcon from "@mui/icons-material/Payment";
import EmailIcon from "@mui/icons-material/Email";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import CalculateIcon from "@mui/icons-material/Calculate";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import BusinessIcon from "@mui/icons-material/Business";
import DashboardIcon from "@mui/icons-material/Dashboard";

export interface NavItemProps {
  id: number;
  label: string;
  value: string;
  icon: React.ReactElement;
  isId?: boolean;
  isDropdown?: boolean;
  dropdownItems?: NavItemProps[];
}

export const unAuthedPages: NavItemProps[] = [
  {
    id: 1,
    label: "Produkten",
    value: "product",
    isId: true,
    icon: <InfoOutlinedIcon />,
  },
  {
    id: 2,
    label: "Priser",
    value: "pricing",
    isId: true,
    icon: <PaymentIcon />,
  },
  {
    id: 3,
    label: "Kontakta oss",
    value: "contactUs",
    isId: true,
    icon: <EmailIcon />,
  },
];

export const calculationRoutes: NavItemProps[] = [
  {
    id: 4,
    label: "Kalkylationer",
    value: "",
    icon: <CalculateIcon />,
    isDropdown: true,
    dropdownItems: [
      {
        id: 5,
        label: "Flödesberäkning",
        value: "/berakningar/flode",
        icon: <AirIcon />,
      },
      {
        id: 6,
        label: "Effektberäkning",
        value: "/berakningar/effekt",
        icon: <BoltIcon />,
      },
      {
        id: 7,
        label: "KVS-värde",
        value: "/berakningar/kv",
        icon: <CompressIcon />,
      },
    ],
  },
];

export const calendarRoutes: NavItemProps[] = [
  {
    id: 8,
    label: "Kalender",
    value: "/kalender",
    icon: <CalendarMonthTwoToneIcon />,
    isDropdown: true,
    dropdownItems: [
      {
        id: 14,
        label: "Kalender",
        value: "/kalender",
        icon: <CalendarMonthTwoToneIcon />,
      },
      {
        id: 15,
        label: "Resurs Vy",
        value: "/kalender/multi",
        icon: <CalendarMonthTwoToneIcon />,
      },
    ],
  },
];

export const adminPages: NavItemProps[] = [
  {
    id: 9,
    label: "Admin",
    value: "/admin",
    icon: <AdminPanelSettingsIcon />,
    isDropdown: true,
    dropdownItems: [
      {
        id: 10,
        label: "Kontrollpanel",
        value: "/admin",
        icon: <DashboardIcon />,
      },
      {
        id: 11,
        label: "Användare",
        value: "/admin/anvandare",
        icon: <ManageAccountsIcon />,
      },
      {
        id: 12,
        label: "Företag",
        value: "/admin/foretag",
        icon: <BusinessIcon />,
      },
    ],
  },
];

export const moderatorPages: NavItemProps[] = [
  {
    id: 13,
    label: "Kontrollpanel",
    value: "/admin",
    icon: <DashboardIcon />,
  },
];
