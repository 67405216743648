import createCache from "@emotion/cache";
import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  MenuItem,
  Modal,
  OutlinedInput,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import {
  Company,
  CreateUpdateCompany,
} from "../../api/companies/companiesTypes";

export interface CreateCompanyModalProps {
  open: boolean;
  handleClose: () => void;
  createCompany: (user: CreateUpdateCompany) => void;
}

const CreateCompanyModal: React.FC<CreateCompanyModalProps> = ({
  open,
  handleClose,
  createCompany,
}) => {
  const [company, setCompany] = useState<CreateUpdateCompany>({
    name: "",
    phone: "",
  });

  const handleChange = (key: string, value: any) => {
    setCompany({
      ...company,
      [key]: value,
    });
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      sx={{
        overflow: "scroll",
      }}
    >
      <Box
        sx={{
          zIndex: 10,
          position: "absolute" as "absolute",
          left: "50%",
          top: "50%",
          transform: "translate(-50%, -50%)",
          width: {
            xs: 250,
            sm: 350,
            md: 500,
          },
          bgcolor: "background.paper",
          boxShadow: 24,
          border: 0,
          borderRadius: "10px",
          p: 4,

          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <Typography
          id="modal-modal-title"
          variant="h6"
          component="h2"
          sx={{
            mx: "auto",
            textAlign: "center",
          }}
        >
          Skapa ett nytt företag
        </Typography>

        <FormControl sx={{ m: 1, width: "25ch" }} variant="outlined">
          <FormHelperText
            id="name-text"
            sx={{
              fontSize: { xs: "0.8rem", sm: "1rem", md: "1.2rem" },
              color: "primary.main",
              mx: 1,
              mt: 3,
            }}
          >
            Företagsnamn
          </FormHelperText>
          <OutlinedInput
            id="name-input"
            value={company.name}
            onChange={(event) => {
              handleChange("name", event.target.value);
            }}
            aria-describedby="name-text"
            inputProps={{
              "aria-label": "Företagsnamn",
            }}
          />
        </FormControl>

        <FormControl sx={{ m: 1, width: "25ch" }} variant="outlined">
          <FormHelperText
            id="phone-text"
            sx={{
              fontSize: { xs: "0.8rem", sm: "1rem", md: "1.2rem" },
              color: "primary.main",
              mx: 1,
            }}
          >
            Telefonnummer
          </FormHelperText>
          <OutlinedInput
            id="phone-input"
            value={company.phone}
            onChange={(event) => {
              handleChange("phone", event.target.value);
            }}
            aria-describedby="phone-text"
            inputProps={{
              "aria-label": "Telefonnummer",
            }}
          />
        </FormControl>

        <Box
          sx={{
            my: 3,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: {
              xs: "column",
              sm: "column",
              md: "row",
            },
          }}
        >
          <Button
            sx={{
              mr: 1,
              my: 1,
            }}
            onClick={() => {
              createCompany(company);
              handleClose();
            }}
          >
            Skapa användare
          </Button>
          <Button
            sx={{
              ml: 1,
              my: 1,
            }}
            onClick={() => handleClose()}
          >
            Avbryt
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default CreateCompanyModal;
