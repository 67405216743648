import { Box, Button } from "@mui/material";
import {
  DataGrid,
  GridCellEditCommitParams,
  GridColDef,
  GridToolbar,
  svSE,
} from "@mui/x-data-grid";
import { formatRelative } from "date-fns";
import { sv } from "date-fns/locale";
import { useState } from "react";
import React from "react";
import { useNavigate } from "react-router-dom";
import { Company } from "../../../../api/companies/companiesTypes";
import {
  CreateUser,
  RoleText,
  User,
  UserRole,
} from "../../../../api/users/usersTypes";
import UsersController from "../../../../api/users/users";
import useAuth from "../../../../hooks/auth";
import CreateUserModal from "../../../../components/modals/createUser";

export interface AccountsTabProps {
  company: Company;
  updateUser: (user: GridCellEditCommitParams) => void;
  createUser: (user: CreateUser) => void;
}

const AccountsTab: React.FC<AccountsTabProps> = ({
  company,
  updateUser,
  createUser,
}) => {
  const navigate = useNavigate();

  const columns: GridColDef[] = [
    { field: "id", headerName: "ID", width: 150 },
    {
      field: "firstname",
      headerName: "Förnamn",
      width: 150,
      editable: true,
    },
    {
      field: "lastname",
      headerName: "Efternamn",
      width: 150,
      editable: true,
    },
    {
      field: "email",
      headerName: "Email",
      width: 150,
      editable: true,
    },
    {
      field: "role",
      headerName: "Roll",
      width: 150,
      valueGetter(params) {
        return RoleText[params.row.role as UserRole];
      },
    },
    {
      field: "companyId",
      headerName: "Företag",
      width: 150,
      valueGetter() {
        return company.name;
      },
    },
    {
      field: "updatedAt",
      headerName: "Senast uppdaterad",
      width: 150,
      valueFormatter(params) {
        return formatRelative(new Date(params.value), new Date(), {
          locale: sv,
        });
      },
    },
    {
      field: "createdAt",
      headerName: "Skapad",
      width: 150,
      valueFormatter(params) {
        return formatRelative(new Date(params.value), new Date(), {
          locale: sv,
        });
      },
    },
    {
      field: "",
      headerName: "",
      width: 150,
      renderCell(cell) {
        return (
          <Button onClick={() => navigate("/admin/anvandare/" + cell.row.id)}>
            Se mer
          </Button>
        );
      },
    },
  ];

  const [modalOpen, setModalOpen] = useState<boolean>(false);

  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      <CreateUserModal
        open={modalOpen}
        handleClose={() => setModalOpen(false)}
        companies={[company]}
        createUser={createUser}
      ></CreateUserModal>

      <Button
        sx={{
          mx: "auto",
          mb: 3,
        }}
        onClick={() => setModalOpen(true)}
      >
        Lägg till ny användare
      </Button>
      <Box
        sx={{
          width: "100%",
        }}
      >
        <DataGrid
          sx={{
            height: "400px",
            width: "100%",
            "& .MuiDataGrid-toolbarContainer button": {
              height: "24px",
              mr: 1,
            },
          }}
          localeText={svSE.components.MuiDataGrid.defaultProps.localeText}
          rows={company.users || []}
          columns={columns}
          components={{ Toolbar: GridToolbar }}
          onCellEditCommit={updateUser}
        ></DataGrid>
      </Box>
    </Box>
  );
};

export default AccountsTab;
