import { Navigate, Routes, Route } from "react-router-dom";
import { UserRole } from "./api/users/usersTypes";
import Navbar from "./components/navbar";
import useAuth from "./hooks/auth";
import EffectCalculation from "./pages/calculations/effect/effect";
import FlowCalculation from "./pages/calculations/flow/flow";
import Calendar from "./pages/calendar/calendar/calendar";
import Dashboard from "./pages/dashboard/dashboard";
import Home from "./pages/home/home";
import Login from "./pages/login/login";
import AdminDashboard from "./pages/admin/adminDashboard/adminDashboard";
import AdminUsers from "./pages/admin/adminUsers/adminUsers";
import Settings from "./pages/settings/settings";
import AdminCompanies from "./pages/admin/adminCompanies/adminCompanies";
import AdminCompany from "./pages/admin/adminCompany/adminCompany";
import CreateAppointment from "./pages/appointments/createAppointment/createAppointment";
import KVCalculation from "./pages/calculations/kv/kv";
import UpdateAppointment from "./pages/appointments/updateAppointment/updateAppointment";
import MultiCalendarPage from "./pages/calendar/multiCalendar/multiCalendar";

function App() {
  const auth = useAuth();

  return (
    <>
      <Navbar />
      <Routes>
        {auth.authed ? (
          <>
            <Route path="/" element={<Dashboard />} />
            <Route path="/installningar/:id" element={<Settings />} />
            <Route path="/kalender" element={<Calendar />} />
            <Route path="/kalender/multi" element={<MultiCalendarPage />} />
            <Route path="/kalender/bokning" element={<CreateAppointment />} />
            <Route
              path="/kalender/bokning/:id"
              element={<UpdateAppointment />}
            />
            <Route path="/berakningar/flode" element={<FlowCalculation />} />
            <Route path="/berakningar/effekt" element={<EffectCalculation />} />
            <Route path="/berakningar/kv" element={<KVCalculation />} />
            <Route element={<Navigate to="/" />} />
          </>
        ) : (
          <>
            <Route path="/" element={<Home />} />
            <Route path="/login" element={<Login />} />
            <Route element={<Navigate to="/" />} />
          </>
        )}

        {auth.authed && auth.me.role === UserRole.ADMIN ? (
          <>
            <Route path="/admin" element={<AdminDashboard />} />
            <Route path="/admin/anvandare" element={<AdminUsers />} />
            <Route path="/admin/anvandare/:id" element={<Settings />} />
            <Route path="/admin/foretag" element={<AdminCompanies />} />
            <Route path="/admin/foretag/:id" element={<AdminCompany />} />
          </>
        ) : null}

        {auth.authed && auth.me.role === UserRole.MODERATOR ? (
          <>
            <Route path="/admin" element={<AdminDashboard />} />
            <Route path="/admin/anvandare/:id" element={<Settings />} />
          </>
        ) : null}
      </Routes>
    </>
  );
}

export default App;
