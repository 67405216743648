import { BaseDTO } from "../apiTypes";
import { Company } from "../companies/companiesTypes";

export enum UserRole {
  ADMIN = "ADMIN",
  MODERATOR = "MODERATOR",
  USER = "USER",
}

export const RoleText = {
  ADMIN: "Super Admin",
  MODERATOR: "Administratör",
  USER: "Användare",
};

export const roles = [
  {
    label: RoleText.USER,
    value: UserRole.USER,
  },
  {
    label: RoleText.MODERATOR,
    value: UserRole.MODERATOR,
  },
];

export const adminRoles = [
  ...roles,
  {
    label: RoleText.ADMIN,
    value: UserRole.ADMIN,
  },
];

export interface User {
  id: string;

  role: UserRole;

  firstname: string;

  lastname: string;

  email: string;

  company?: Company;

  companyId: string;

  createdAt: Date;

  updatedAt: Date;
}

export interface CreateUser {
  role: UserRole;

  firstname: string;

  lastname: string;

  email: string;

  companyId: string;

  password: string;
}

export interface UpdateUser {
  role: UserRole;

  firstname: string;

  lastname: string;

  email: string;

  companyId: string;

  password?: string;
}

export interface DeleteUserDTO extends BaseDTO {
  data: null;
}

export interface CreateUserDTO extends BaseDTO {
  data: User | null;
}

export interface AllUserDTO extends BaseDTO {
  data: User[];
}

export interface UserByIdDTO extends BaseDTO {
  data: User | null;
}
