import { AlignVerticalCenter } from "@mui/icons-material";
import {
  Box,
  Card,
  CardContent,
  Container,
  FormControl,
  FormHelperText,
  InputAdornment,
  MenuItem,
  OutlinedInput,
  TextField,
  Typography,
} from "@mui/material";
import { alignProperty } from "@mui/material/styles/cssUtils";
import { useEffect, useState } from "react";
import {
  getNeededEffect,
  getNeededEffectDimensions,
} from "../../../calculations/flowAndEffect/effect";
import {
  Dimensions,
  TemperatureDifference,
} from "../../../calculations/flowAndEffect/sharedTypes";

interface Values {
  pressure: number;
  isBar: boolean;
  flow: number;
  isPerSecond: boolean;
}

interface Results {
  KV: number;
}

const units = {
  flow: [
    {
      label: "liter/sekund",
      value: 1,
    },
    {
      label: "liter/timme",
      value: 0,
    },
  ],
  pressure: [
    {
      label: "Bar",
      value: 1,
    },
    {
      label: "Mega Pascal",
      value: 0,
    },
  ],
};

const KVCalculation = () => {
  const [values, setValues] = useState<Values>({
    isPerSecond: false,
    flow: 0,
    isBar: true,
    pressure: 1,
  });

  const [results, setResults] = useState<Results>({
    KV: 0,
  });

  useEffect(() => {}, [values]);

  const handleChange = (
    key: string,
    value: boolean | number | TemperatureDifference
  ) => {
    setValues({
      ...values,
      [key]: value,
    });
  };

  return (
    <>
      <Container maxWidth={false}>
        <Typography
          variant="h3"
          sx={{
            mx: "auto",
            mb: 3,
            mt: 5,
            textAlign: "center",
          }}
        >
          Beräkna KVS-värde
        </Typography>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <FormControl sx={{ m: 1, width: "52ch" }} variant="outlined">
            <FormHelperText
              id="flow-text"
              sx={{
                fontSize: { xs: "0.8rem", sm: "1rem", md: "1.2rem" },
                color: "primary.main",
                mx: 1,
              }}
            >
              Flöde
            </FormHelperText>
            <OutlinedInput
              id="flow-input"
              value={values.flow}
              type="number"
              onChange={(event) => {
                handleChange("flow", Number(event.target.value));
              }}
              endAdornment={
                <InputAdornment position="end">
                  {values.isPerSecond ? "liter/sekund" : "liter/timme"}
                </InputAdornment>
              }
              aria-describedby="flow-text"
              inputProps={{
                "aria-label": "Flow",
              }}
            />
          </FormControl>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <FormControl sx={{ m: 1, width: "52ch" }} variant="outlined">
            <FormHelperText
              id="flow-text"
              sx={{
                fontSize: { xs: "0.8rem", sm: "1rem", md: "1.2rem" },
                color: "primary.main",
                mx: 1,
              }}
            >
              Tryck
            </FormHelperText>
            <OutlinedInput
              id="flow-input"
              value={values.pressure}
              type="number"
              onChange={(event) => {
                handleChange("flow", Number(event.target.value));
              }}
              endAdornment={
                <InputAdornment position="end">
                  {values.isBar ? "Bar" : "MPa"}
                </InputAdornment>
              }
              aria-describedby="flow-text"
              inputProps={{
                "aria-label": "Flow",
              }}
            />
          </FormControl>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <FormControl sx={{ width: "25ch" }} variant="outlined">
            <FormHelperText
              id="isPerSecond-difference-text"
              sx={{
                fontSize: { xs: "0.8rem", sm: "1rem", md: "1.2rem" },
                color: "primary.main",
                mx: 1,
              }}
            >
              Enhet
            </FormHelperText>
            <TextField
              id="isPerSecond-input"
              value={Number(values.isPerSecond)}
              onChange={(event) =>
                handleChange("isPerSecond", !!Number(event.target.value))
              }
              aria-describedby="isPerSecond-difference-text"
              inputProps={{
                "aria-label": "Unit",
              }}
              select
            >
              {units.flow.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </FormControl>

          <FormControl sx={{ m: 1, width: "25ch" }} variant="outlined">
            <FormHelperText
              id="temperature-difference-text"
              sx={{
                fontSize: { xs: "0.8rem", sm: "1rem", md: "1.2rem" },
                color: "primary.main",
                mx: 1,
              }}
            >
              Tryck-enhet
            </FormHelperText>
            <TextField
              id="temperatureDifference-input"
              value={values}
              onChange={(event) =>
                handleChange(
                  "temperatureDifference",
                  Number(event.target.value)
                )
              }
              aria-describedby="temperature-difference-text"
              inputProps={{
                "aria-label": "Temperature difference",
              }}
              select
            >
              {units.pressure.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </FormControl>
        </Box>

        <Typography
          variant="h4"
          sx={{
            mx: "auto",
            mb: 3,
            mt: 5,
            textAlign: "center",
          }}
        >
          KV
        </Typography>
        <Typography
          variant="h5"
          sx={{
            mx: "auto",
            textAlign: "center",
          }}
        >
          {results.KV}
        </Typography>
      </Container>
    </>
  );
};

export default KVCalculation;
