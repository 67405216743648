import { AxiosError } from "axios";
import { getUnixTime } from "date-fns";
import createAppointment from "../../pages/appointments/createAppointment/createAppointment";
import Controller from "../api";
import {
  CreateUpdateAppointment,
  DeleteAppointmentDTO,
  GetAppointmentDTO,
  GetMultipleAppointmentDTO,
  GetTagsDTO,
} from "./appointmentTypes";

export default class AppointmentsController extends Controller {
  public async getUserAppointmentsWithRange(
    id: string,
    start: Date,
    end: Date
  ): Promise<GetMultipleAppointmentDTO> {
    const response = await this.get(
      `/appointments/user/${id}?start=${getUnixTime(start)}&end=${getUnixTime(
        end
      )}`
    );

    if (Controller.isGoodResponse(response.status)) {
      return { ...response, isError: false } as GetMultipleAppointmentDTO;
    } else {
      let error = response as AxiosError;
      return {
        status: error.response?.status || 0,
        statusText: error.response?.statusText || "",
        data: [],
        isError: true,
      };
    }
  }

  public async getCompanyAppointmentsWithRange(
    id: string,
    start: Date,
    end: Date
  ): Promise<GetMultipleAppointmentDTO> {
    const response = await this.get(
      `/appointments/company/${id}?start=${getUnixTime(
        start
      )}&end=${getUnixTime(end)}`
    );

    if (Controller.isGoodResponse(response.status)) {
      return { ...response, isError: false } as GetMultipleAppointmentDTO;
    } else {
      let error = response as AxiosError;
      return {
        status: error.response?.status || 0,
        statusText: error.response?.statusText || "",
        data: [],
        isError: true,
      };
    }
  }

  public async getFeed(amount = 3): Promise<GetMultipleAppointmentDTO> {
    const response = await this.get("/appointments/feed?amount=" + amount);

    if (Controller.isGoodResponse(response.status)) {
      return { ...response, isError: false } as GetMultipleAppointmentDTO;
    } else {
      let error = response as AxiosError;
      return {
        status: error.response?.status || 0,
        statusText: error.response?.statusText || "",
        data: [],
        isError: true,
      };
    }
  }

  public async getAppointment(id: string): Promise<GetAppointmentDTO> {
    const response = await this.get("/appointments/" + id);

    if (Controller.isGoodResponse(response.status)) {
      return { ...response, isError: false } as GetAppointmentDTO;
    } else {
      let error = response as AxiosError;
      return {
        status: error.response?.status || 0,
        statusText: error.response?.statusText || "",
        data: null,
        isError: true,
      };
    }
  }

  public async createAppointment(
    appointmentDetails: CreateUpdateAppointment
  ): Promise<GetAppointmentDTO> {
    const response = await this.post("/appointments", appointmentDetails);

    if (Controller.isGoodResponse(response.status)) {
      return { ...response, isError: false } as GetAppointmentDTO;
    } else {
      let error = response as AxiosError;
      return {
        status: error.response?.status || 0,
        statusText: ((error.response?.data as any).message as string) || "",
        data: null,
        isError: true,
      };
    }
  }

  public async updateAppointment(
    id: string,
    appointmentDetails: CreateUpdateAppointment
  ): Promise<GetAppointmentDTO> {
    const response = await this.put("/appointments/" + id, appointmentDetails);

    if (Controller.isGoodResponse(response.status)) {
      return { ...response, isError: false } as GetAppointmentDTO;
    } else {
      let error = response as AxiosError;
      return {
        status: error.response?.status || 0,
        statusText: ((error.response?.data as any).message as string) || "",
        data: null,
        isError: true,
      };
    }
  }

  public async deleteAppointment(id: string): Promise<DeleteAppointmentDTO> {
    const response = await this.delete("/appointments/" + id);

    if (Controller.isGoodResponse(response.status)) {
      return {
        status: response.status,
        statusText: response.status,
        data: null,
        isError: false,
      } as DeleteAppointmentDTO;
    } else {
      let error = response as AxiosError;
      return {
        status: error.response?.status || 0,
        statusText: ((error.response?.data as any).message as string) || "",
        data: null,
        isError: true,
      };
    }
  }

  public async getTags(id: string): Promise<GetTagsDTO> {
    const response = await this.get("/appointments/tags/company/" + id);
    console.log(response);

    if (Controller.isGoodResponse(response.status)) {
      return { ...response, isError: false } as GetTagsDTO;
    } else {
      let error = response as AxiosError;
      return {
        status: error.response?.status || 0,
        statusText: error.response?.statusText || "",
        data: [],
        isError: true,
      };
    }
  }
}
