import {
  Alert,
  Button,
  Card,
  CardActions,
  CardContent,
  Container,
  Link,
  Snackbar,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import useAuth from "../../hooks/auth";

const Login = () => {
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState(false);
  const [password, setPassword] = useState("");
  const [passwordError, setPasswordError] = useState(false);

  const [error, setError] = useState(false);

  const auth = useAuth();
  const navigate = useNavigate();

  const submitHandler = async () => {
    if (isValidEmail()) {
      if (isValidPassword()) {
        const failed = !(await auth.login({
          email,
          password,
          stayLoggedIn: true,
        })) as boolean;

        if (failed) {
          setError(true);
          setEmailError(true);
          setPasswordError(true);
        }
      } else {
        setPasswordError(true);
      }
    } else {
      setEmailError(true);
      if (!isValidPassword()) setPasswordError(true);
    }
  };

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      submitHandler();
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    switch (e.target.name) {
      case "email":
        setEmail(e.target.value);
        setEmailError(false);
        break;
      case "password":
        setPassword(e.target.value);
        setPasswordError(false);
        break;
      default:
        break;
    }
  };

  const isValidEmail = (): Boolean => {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  };

  const isValidPassword = (): Boolean => {
    return password.length >= 4;
  };

  return (
    <Container
      sx={{
        height: "calc(100vh - 50px)",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        textAlign: "center",
      }}
    >
      <Card
        variant="outlined"
        sx={{
          maxWidth: "500px",
          padding: "2rem",
        }}
      >
        <CardContent>
          <Typography variant="h2">Logga in</Typography>
        </CardContent>
        <CardActions
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <TextField
            name="email"
            onKeyPress={handleKeyPress}
            id="email"
            type="email"
            error={emailError}
            label="Email"
            onChange={handleChange}
            variant="outlined"
            sx={{
              mt: "2rem",
            }}
          />
          <TextField
            name="password"
            onKeyPress={handleKeyPress}
            type="password"
            id="password"
            label="Password"
            onChange={handleChange}
            error={passwordError}
            variant="outlined"
            sx={{
              mt: "1rem",
              ml: "0 !important",
            }}
          />

          <Button
            variant="contained"
            color="secondary"
            sx={{
              mt: "1rem",
            }}
            onClick={submitHandler}
            type="submit"
          >
            Logga in
          </Button>

          <Button
            sx={{
              backgroundColor: "primary.light",
              color: "primary.main",
              mt: "0.5rem",
            }}
            onClick={() => navigate("/forgot-password")}
          >
            Glömt lösenordet?
          </Button>
        </CardActions>
      </Card>

      <Snackbar
        open={error}
        autoHideDuration={6000}
        onClose={() => {
          setError(false);
        }}
      >
        <Alert severity="error" sx={{ width: "100%" }}>
          Det gick inte att logga in, försök igen.
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default Login;
