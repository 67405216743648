import {
  Box,
  Button,
  Container,
  FormControl,
  FormHelperText,
  Modal,
  OutlinedInput,
  TextField,
  Typography,
} from "@mui/material";
import { GridSaveAltIcon } from "@mui/x-data-grid";
import { DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { format } from "date-fns";
import { sv } from "date-fns/locale";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import SaveIcon from "@mui/icons-material/Save";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  Appointment,
  CreateUpdateAppointment,
} from "../../api/appointments/appointmentTypes";
import AppointmentsController from "../../api/appointments/appointments";
import YesNoModal from "./yesNo";
import { NoEncryption } from "@mui/icons-material";

export interface QuickUpdateAppointmentModalProps {
  open: boolean;
  setOpen: (value: boolean) => void;
  appointment: Appointment | undefined;
  submit: (id: string, appointment: CreateUpdateAppointment) => void;
  deleteAppointment: (id: string) => void;
  setError: (error: string) => void;
}

const QuickUpdateAppointmentModal: React.FC<
  QuickUpdateAppointmentModalProps
> = ({ open, setOpen, appointment, submit, deleteAppointment, setError }) => {
  const navigate = useNavigate();

  const [updateAppointment, setUpdateAppointment] =
    useState<CreateUpdateAppointment | null>();
  const [confirmOpen, setConfirmOpen] = useState<boolean>(false);

  useEffect(() => {
    setUpdateAppointment(appointment);
  }, [appointment]);

  const handleChange = (key: string, value: any) => {
    if (updateAppointment) {
      setUpdateAppointment({ ...updateAppointment, [key]: value });
    }
  };

  const onSubmit = () => {
    if (appointment && updateAppointment) {
      submit(appointment.id, updateAppointment);
    } else {
      setError(
        "Något gick fel, prova att klicka mer och uppdatera via panelen där"
      );
    }

    setOpen(false);
  };

  const removeAppointment = () => {
    deleteAppointment(appointment?.id || "");
    setOpen(false);
  };

  return (
    <>
      <YesNoModal
        open={confirmOpen}
        close={(agreed) => {
          if (agreed) {
            removeAppointment();
          }
          setConfirmOpen(false);
        }}
        title="Vill du ta bort bokningen?"
        description={"Vill du ta bort bokningen: " + appointment?.title}
      ></YesNoModal>
      <Modal
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{
          overflow: "scroll",
        }}
      >
        <Box
          sx={{
            position: "absolute" as "absolute",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            width: {
              xs: 250,
              sm: 350,
              md: 500,
            },
            top: {
              xs: "0",
              md: "50%",
            },
            left: "50%",
            transform: { xs: "translateX(-50%)", md: "translate(-50%, -50%)" },
            bgcolor: "background.paper",
            border: "1px solid",
            borderColor: "primary.main",
            borderRadius: "15px",
            p: 4,
            pb: 1,
          }}
        >
          {appointment ? (
            <>
              <Container
                maxWidth={"md"}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                  mb: 5,
                }}
              >
                <Typography variant="h5">Ändra bokning</Typography>

                <FormControl
                  sx={{
                    m: 1,
                    width: {
                      xs: "25ch",
                      md: "52ch",
                    },
                  }}
                  variant="outlined"
                >
                  <FormHelperText
                    id="name-text"
                    sx={{
                      fontSize: { xs: "0.8rem", sm: "1rem", md: "1.2rem" },
                      color: "primary.main",
                      mx: 1,
                      mt: 3,
                    }}
                  >
                    Bokningstitel
                  </FormHelperText>
                  <OutlinedInput
                    id="name-input"
                    value={updateAppointment?.title}
                    onChange={(event) => {
                      handleChange("title", event.target.value);
                    }}
                    aria-describedby="name-text"
                    inputProps={{
                      "aria-label": "Bokningstitel",
                    }}
                  />
                </FormControl>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-around",
                      alignItems: "center",
                      flexDirection: {
                        xs: "column",
                        md: "row",
                      },
                    }}
                  >
                    <FormControl
                      sx={{ m: 1, width: "25ch" }}
                      variant="outlined"
                    >
                      <FormHelperText
                        id="start-text"
                        sx={{
                          fontSize: { xs: "0.8rem", sm: "1rem", md: "1.2rem" },
                          color: "primary.main",
                          mx: 1,
                          mt: 3,
                        }}
                      >
                        Börjar
                      </FormHelperText>
                      <DateTimePicker
                        renderInput={(props) => <TextField {...props} />}
                        value={updateAppointment?.start}
                        onChange={(newValue) => {
                          handleChange("start", newValue);
                        }}
                        ampm={false}
                      />
                    </FormControl>
                    <FormControl
                      sx={{ m: 1, width: "25ch" }}
                      variant="outlined"
                    >
                      <FormHelperText
                        id="end-text"
                        sx={{
                          fontSize: { xs: "0.8rem", sm: "1rem", md: "1.2rem" },
                          color: "primary.main",
                          mx: 1,
                          mt: 3,
                        }}
                      >
                        Slutar
                      </FormHelperText>
                      <DateTimePicker
                        renderInput={(props) => <TextField {...props} />}
                        value={updateAppointment?.end}
                        onChange={(newValue) => {
                          handleChange("end", newValue);
                        }}
                        ampm={false}
                      />
                    </FormControl>
                  </Box>
                </LocalizationProvider>

                <FormControl
                  sx={{
                    m: 1,
                    width: {
                      xs: "25ch",
                      md: "52ch",
                    },
                  }}
                  variant="outlined"
                >
                  <FormHelperText
                    id="description-text"
                    sx={{
                      fontSize: { xs: "0.8rem", sm: "1rem", md: "1.2rem" },
                      color: "primary.main",
                      mx: 1,
                      mt: 3,
                    }}
                  >
                    Beskrivning
                  </FormHelperText>
                  <OutlinedInput
                    id="description-input"
                    multiline
                    minRows={4}
                    value={updateAppointment?.description}
                    onChange={(event) => {
                      handleChange("description", event.target.value);
                    }}
                    aria-describedby="description-text"
                    inputProps={{
                      "aria-label": "Beskrivning",
                    }}
                  />
                </FormControl>

                <Box
                  sx={{
                    display: "flex",
                    justifyContent: {
                      xs: "center",
                      md: "space-around",
                    },
                    alignItems: "center",
                    flexDirection: {
                      xs: "column-reverse",
                      md: "row",
                    },
                    mt: 3,
                    width: "100%",
                  }}
                >
                  <Button
                    sx={{
                      mr: { xs: 0, md: 2 },
                      backgroundColor: "error.main",
                    }}
                    onClick={() => setConfirmOpen(true)}
                  >
                    Radera <DeleteIcon sx={{ ml: 1 }} />
                  </Button>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: {
                        xs: "center",
                        md: "space-around",
                      },
                      alignItems: "center",
                      flexDirection: {
                        xs: "column",
                        md: "row",
                      },
                    }}
                  >
                    <Button
                      onClick={() =>
                        navigate("/kalender/bokning/" + appointment?.id)
                      }
                      sx={{}}
                    >
                      Fler alternativ
                    </Button>

                    <Button
                      onClick={() => onSubmit()}
                      sx={{
                        ml: { xs: 0, md: 2 },
                        backgroundColor: "success.main",

                        my: {
                          xs: 2,
                          md: 0,
                        },
                      }}
                    >
                      Spara <SaveIcon sx={{ ml: 1 }} />
                    </Button>
                  </Box>
                </Box>
              </Container>
            </>
          ) : (
            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
              Bokningen kunde inte hittas
            </Typography>
          )}
        </Box>
      </Modal>
    </>
  );
};

export default QuickUpdateAppointmentModal;
